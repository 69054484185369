import React from "react";
import Header from '../components/parts/partials/header';
import useBodyClass from '../helpers/helper';
import { Helmet } from "react-helmet";
// import LoginForm from '../components/parts/login/loginForm';
import LoginSimpleForm from '../components/parts/login/loginSimpleForm';
import Preloader from '../components/parts/preloader/preloader';
import { useState, useEffect} from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { API_AUTH_CALLBACK_URL } from '../constants';
import axios from "axios";
import { useDispatch } from "react-redux";
import { addUserAtts } from "../actions/actions";
import queryString from 'query-string';


const LoginTokenPage = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userParams, setUserParams] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setUserParams(queryString.parse(props.location.search));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      if (typeof userParams != 'undefined' && userParams != null) {
        if (isSending) return;
        // update state
        setLoading(true);
        setIsSending(true);
        // send the actual request
        axios.post(API_AUTH_CALLBACK_URL, userParams).then((res) => {
          if (res.data.status) {
            localStorage.setItem("token", res.data.token);
            dispatch(
              addUserAtts({
                name: res.data.data.name,
                email: res.data.data.email,
              })
            );
            setLoading(false);
            setIsSending(false);
            if (res.data.first_login) {
              history.push("/account-settings");
            } else {
              history.push('/');
            }
          } else {
            setError(t("auth.failed"));
          }
          setLoading(false);
        }).catch((error) => {
             console.error("FAILED TO LOGIN");
              setLoading(false);
             setError(t("auth.failed"));
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userParams]);

  useBodyClass("preview-page-login");

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <div id="wrapper-preview-page-login">
        <Helmet>
          <title>
            {process.env.REACT_APP_APPLICATION_NAME} | {t("login.page_title")}
          </title>
        </Helmet>
        <section className="preview-page-login">
          <Header />
          <LoginSimpleForm errorMessage={error}/>
        </section>
      </div>
    );
  }
}
export default LoginTokenPage;