import React from 'react'
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function ErrorPage() {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <div id="wrapper-error">
            <section className="error">
                <div className="gridContainer body_m error_container flex col between padding_20">
                    <span className="s_20"></span>

                    <div className="flex col center w_100">
                        <img src="../assets/img/error.svg" alt="Error Notify" />
                        <span className="s_30"></span>
                        <div className="txt-xl-size bold center">{t("errorpage.title")}</div>
                        <span className="s_5"></span>
                        <div className="txt-m-size">{t("errorpage.p1")} <br />{t("errorpage.p2")}</div>
                    </div>
                    <div className="flex center w_100">
                        <button className="cta w_100" onClick={()=> history.push('/')}>{t("errorpage.ok")}</button>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default ErrorPage;