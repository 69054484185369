import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API_LOGOUT_URL } from '../../../../constants';
import { API_SET_USER_SETTINGS_POST_URL, API_GET_USER_SETTINGS_POST_URL } from '../../../../constants';
import axios from "axios";
import { useSelector, } from "react-redux";


const Settings = ({ show, close }) => {
	const { t, i18n } = useTranslation();
	const [showLanguange, setShowLanguange] = useState(false);
	const [showInAppNotifications, setShowInAppNotifications] = useState(false);
	const [showEmailNotifications, setShowEmailAppNotifications] = useState(false);
	const [settings, setSettings] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);
	const is_online = useSelector(state => state.app.app_online);

	useEffect(() => {
		const getSettings = async () => {
			let body = {};
			body['token'] = localStorage.getItem('token');
			await axios.post(API_GET_USER_SETTINGS_POST_URL, body).then((res) => {
				if (res.data) {
					setSettings(res.data[0]);
				} else {
					console.error('Unable to GET notifications');
				}
			}).catch(error => {
				if (error.status === 403) {
					localStorage.removeItem('token');
					window.location.href = '/login';
				} else {
					// window.location.href = '/error';
					console.log(error);
				}
			});
		};

		if(show){
			getSettings();
		}else{
			setShowEmailAppNotifications(false);
			setShowInAppNotifications(false);
			setShowLanguange(false);
		}
	}, [show]);

	const logout = async () => {
		let body = {};
		body['token'] = localStorage.getItem('token');
		await axios.post(API_LOGOUT_URL, body).then((res) => {
			localStorage.removeItem('token');
			window.location.href = '/login';
		}).catch(error => {
			localStorage.removeItem('token');
			window.location.href = '/login';
		});
	}
	const languageChanger = (val) => {
		localStorage.setItem('lang',val)
		i18n.changeLanguage(val);
		setSettings(prevState => ({
			...prevState,
			'language': val
		}));
		if(is_online)
			submitSettings('language', val);
	};
	const notificationSettings = (type) => {
		let value = settings[type] ? 0 : 1;
		setSettings(prevState => ({
			 ...prevState,
			[type]: value
		}));
		submitSettings(type, value);
	};
	const anonymousFeedback = (val) => {
		let value = settings.get_anonymous_feedback ? 0 : 1;
		setSettings( prevState => ({
			...prevState,
			'get_anonymous_feedback': value
		}));
		submitSettings('get_anonymous_feedback', value);
	};

	const submitSettings = async (key, value) => {
		let body = {};
		body[key] = value;
		body['token'] = localStorage.getItem('token');
		axios.post(API_SET_USER_SETTINGS_POST_URL, body).then((res) => {
			if (res.data.status) {
				return true;
			} else {
				console.error('Unable to POST settings');
			}
		}).catch(error => {
			console.log(error);
		});
	};
	return (
		<section className={show ? "setting-drawer active" : "setting-drawer"}>
			<div className="osk-header_settings flex between padding_0_20" >
				<div data-attr="back-to-home" className="osk-header_settings_logo items center">
					<i className="icon icon-arrow-previous txt-m-size" onClick={() => close()}></i>
					<span className="txt-xxl-size txt-color-black padding_0_0_0_20 bold" onClick={() => close()} >{t("settings.page_title")}</span>
				</div>
				<div className="osk-header_settings_clear items bold txt-m-size">
					{/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
					<a href="#" className="txt-color-digital_mid_grey_1" onClick={logout}>{t("settings.log_out")}</a>
				</div>
			</div>
			<section className="settings">
				<span className="s_10"></span>
				<div className="settings_container text-left">

					<div className="settings_container_item flex between padding_20 bg-color-white" data-attr="open-settings-lang" onClick={() => setShowLanguange(true)}>
						<div className="items">
							<img width="30" src="../assets/img/lng_icon.png" alt="Language Icon" />
							<div className="padding_0_0_0_20">
								<div className="txt-m-size bold">{t("settings.language")}</div>
								<span className="s_5"></span>
								<div className="txt-s-size txt-color-digital_mid_grey_1">
									{
										settings !== null ? (
											settings.language === 'sk' ? t("settings.language_sk") : t("settings.language_en")
										) : null
									}
								</div>
							</div>
						</div>
						<div className="items center"><i className="icon icon-arrow-next"></i></div>
					</div>
					{
						is_online ? (
							<div className="settings_container_item flex between padding_20 bg-color-white">
								<div className="items">
									<img width="30" src="../assets/img/fashion.svg" alt="fashion icon" />
									<label htmlFor="anonymous" className="padding_0_0_0_20">
										<div className="txt-m-size bold">{t("settings.anonymus_feedback")}</div>
										<span className="s_5"></span>
										<div className="txt-s-size txt-color-digital_mid_grey_1">{ settings.get_anonymous_feedback ? t("settings.enabled") : t("settings.disabled")}</div>
									</label>
								</div>
								<div className="items center">
									{
										settings != null ? (
											<label className="switch">
												<input id="anonymous" type="checkbox" checked={settings.get_anonymous_feedback === 1 ? true : false} onChange={anonymousFeedback} />
												<span className="slider"></span>
												<span className="unchecked"></span>
											</label>
										): null
									}
								</div>
							</div>
						) : null
					}
					{
						is_online ? (
							<div className="settings_container_item flex between padding_20 bg-color-white" data-attr="open-settings-app" onClick={() => setShowInAppNotifications(true)}>
								<div className="items">
									<i className="icon icon-Notification-Bell txt-color-digital_dark_grey_2 txt-caption-m-size"></i>
									<div className="padding_0_0_0_20">
										<div className="txt-m-size bold">{t("settings.notifications_inapp")}</div>
										<span className="s_5"></span>
										<div className="txt-s-size txt-color-digital_mid_grey_1">{settings.feedback_app === 1 ? t("settings.new_feedback") : null}{settings.feedback_app === 1 && settings.howto_app === 1 ? ',' : null} {settings.howto_app === 1 ? t("settings.new_tips") : null}</div>
									</div>
								</div>
								<div className="items center"><i className="icon icon-arrow-next"></i></div>
							</div>
						) : null
					}
					{
						is_online ? (
							<div className="settings_container_item flex between padding_20 bg-color-white" data-attr="open-settings-mail" onClick={() => setShowEmailAppNotifications(true)}>
								<div className="items">
									<i className="icon icon-email txt-color-digital_dark_grey_2 txt-caption-m-size"></i>
									<div className="padding_0_0_0_20">
										<div className="txt-m-size bold">{t("settings.email_notification")}</div>
										<span className="s_5"></span>
								<div className="txt-s-size txt-color-digital_mid_grey_1">{settings.feedback_email === 1 ? t("settings.new_feedback") : null}{settings.feedback_email === 1 && settings.howto_email === 1 ? ',' : null} {settings.howto_email === 1 ? t("settings.new_tips") : null}</div>
									</div>
								</div>
								<div className="items center"><i className="icon icon-arrow-next"></i></div>
							</div>
						): null
					}
				</div>
			</section>

			<section className={showLanguange ? "setting-drawer-lang active" : "setting-drawer-lang"}>
				<div className="osk-header_settings flex between padding_0_20">
					<div className="osk-header_settings_logo items center" data-attr="close-settings-lang">
						<i className="icon icon-arrow-previous txt-m-size" onClick={() => setShowLanguange(false)} ></i>
						<span className="txt-xxl-size txt-color-black padding_0_0_0_20 bold" onClick={() => setShowLanguange(false)}>{t("settings.language")}</span>
					</div>
					<div className="osk-header_settings_clear items bold txt-m-size">
					</div>
				</div>
				<section className="settings-language">
					<span className="s_10"></span>
					<div className="settings-language_container">
						<div className="settings-language_container_item flex between padding_20 bg-color-white">
							<div className="items">
								<img src="../assets/img/svk-icon.svg" alt="Slovak icon" />
							</div>
							<div className="items center w_100 padding_0_0_0_20">
								<label className="container_radio txt-m-size bold w_100 flex between" htmlFor="radio1">{t("settings.language_sk")}
			                	<input type="radio" id="radio1" name="language" checked={settings.language === 'sk' ? true : false} onChange={() => languageChanger('sk')}/>
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
						<div className="settings-language_container_item flex between padding_20 bg-color-white">
							<div className="items">
								<img src="../assets/img/eng-icon.svg" alt="English icon" />
							</div>
							<div className="items center w_100 padding_0_0_0_20">
								<label className="container_radio txt-m-size bold w_100 flex between" htmlFor="radio2">{t("settings.language_en")}
			               		<input type="radio" id="radio2" name="language" checked={settings.language === 'en' ? true : false} onChange={() => languageChanger('en')} />
									<span className="checkmark"></span>
								</label>
							</div>
						</div>
					</div>
				</section>
				<div className="footer-link settings w_100 mt">
						<div className="padding_20">
							<center>
								<button className="clean flex center" data-attr="modal-box-help" onClick={() => setModalOpen(true)}>
									<img src="../assets/img/assistance.svg" alt="Assistance icon" />
									<span className="txt-color-digital_mid_grey_1 bold padding_0_0_0_10">{t("feedback.need_help_cta")}</span>
								</button>
								<span className="s_20"></span>
							</center>
						</div>
					</div>
			</section>


			<section className={showInAppNotifications ? "setting-drawer-app active" : "setting-drawer-app"}>
			  <div className="osk-header_settings flex between padding_0_20">
			  
			    <div className="osk-header_settings_logo items center" data-attr="close-settings-app">
						<i className="icon icon-arrow-previous txt-m-size" onClick={() => setShowInAppNotifications(false)}></i>
						<span className="txt-xxl-size txt-color-black padding_0_0_0_20 bold" onClick={() => setShowInAppNotifications(false)}>{t("settings.notifications_inapp")}</span>
			    </div>
			  
			    <div className="osk-header_settings_clear items bold txt-m-size">
			    </div>
			  
			  </div>
			  
			    <section className="settings-app">
			      <span className="s_10"></span>
			        <div className="settings-app_container">
			    
			          <div className="settings-app_container_item flex between padding_20 bg-color-white">
			            <div className="items">
			              <i className="icon icon-Notification-Bell txt-color-digital_dark_grey_2 txt-caption-m-size"></i>
			              <label htmlFor="new_feedback" className="padding_0_0_0_20">
							<div className="txt-m-size bold">{t("settings.new_feedback")}</div>
			              </label>
			            </div>
			            <div className="items center">
			              <label className="switch">
							<input id="new_feedback" type="checkbox" checked={settings.feedback_app === 1 ? true : false} onChange={() => notificationSettings('feedback_app')}/>
			                <span className="slider"></span>
			                <span className="unchecked"></span>
			              </label>
			            </div>
			          </div>
			    
			          <div className="settings-app_container_item flex between padding_20 bg-color-white">
			            <div className="items">
			              <i className="icon icon-Notification-Bell txt-color-digital_dark_grey_2 txt-caption-m-size"></i>
			              <label htmlFor="new_how_to" className="padding_0_0_0_20">
							<div className="txt-m-size bold">{t("settings.new_tips")}</div>
			              </label>
			            </div>
			            <div className="items center">
			              <label className="switch">
			                <input id="new_how_to" type="checkbox" checked={settings.howto_app === 1 ? true : false} onChange={() => notificationSettings('howto_app')} />
			                <span className="slider"></span>
			                <span className="unchecked"></span>
			              </label>
			            </div>
			          </div>
			        </div>
			      </section>
				  <div className="footer-link settings w_100 mt">
						<div className="padding_20">
							<center>
								<button className="clean flex center" data-attr="modal-box-help" onClick={() => setModalOpen(true)}>
									<img src="../assets/img/assistance.svg" alt="Assistance icon" />
									<span className="txt-color-digital_mid_grey_1 bold padding_0_0_0_10">{t("feedback.need_help_cta")}</span>
								</button>
								<span className="s_20"></span>
							</center>
						</div>
					</div>
			</section>

			
			<section className={showEmailNotifications ? "setting-drawer-mail active" : "setting-drawer-mail" }>
			  <div className="osk-header_settings flex between padding_0_20">
			  
			    <div className="osk-header_settings_logo items center" data-attr="close-settings-mail">
						<i className="icon icon-arrow-previous txt-m-size" onClick={() => setShowEmailAppNotifications(false)}></i>
						<span className="txt-xxl-size txt-color-black padding_0_0_0_20 bold" onClick={() => setShowEmailAppNotifications(false)}>{t("settings.email_notification")}</span>
			    </div>
			  
			    <div className="osk-header_settings_clear items bold txt-m-size">
			    </div>
			  
			  </div>
			    <section className="settings-mail">
			      <span className="s_10"></span>
			        <div className="settings-mail_container">
			          <div className="settings-mail_container_item flex between padding_20 bg-color-white">
			            <div className="items">
			              <i className="icon icon-email txt-color-digital_dark_grey_2 txt-caption-m-size"></i>
			              <label htmlFor="email1" className="padding_0_0_0_20">
									<div className="txt-m-size bold">{t("settings.new_feedback")}</div>
			              </label>
			            </div>
			            <div className="items center">
			              <label className="switch">
			                <input id="email1" type="checkbox"  checked={settings.feedback_email === 1 ? true : false} onChange={() => notificationSettings('feedback_email')}/>
			                <span className="slider"></span>
			                <span className="unchecked"></span>
			              </label>
			            </div>
			          </div>
			    
			          <div className="settings-mail_container_item flex between padding_20 bg-color-white">
			            <div className="items">
			              <i className="icon icon-email txt-color-digital_dark_grey_2 txt-caption-m-size"></i>
			              <label htmlFor="email2" className="padding_0_0_0_20">
								<div className="txt-m-size bold">{t("settings.new_tips")}</div>
			              </label>
			            </div>
			            <div className="items center">
			              <label className="switch">
			                <input id="email2" type="checkbox" checked={settings.howto_email  === 1 ? true : false} onChange={() => notificationSettings('howto_email')} />
							<span className="slider"></span>
			                <span className="unchecked"></span>
			              </label>
			            </div>
			          </div>
			        </div>
			      </section>
				  <div className="footer-link settings w_100 mt">
						<div className="padding_20">
							<center>
								<button className="clean flex center" data-attr="modal-box-help" onClick={() => setModalOpen(true)}>
									<img src="../assets/img/assistance.svg" alt="Assistance icon" />
									<span className="txt-color-digital_mid_grey_1 bold padding_0_0_0_10">{t("feedback.need_help_cta")}</span>
								</button>
								<span className="s_20"></span>
							</center>
						</div>
					</div>
			</section> 

			<div className="footer-link settings w_100 mt">
				<div className="padding_20">
					<center>
						<button className="clean flex center" data-attr="modal-box-help" onClick={() => setModalOpen(true)}>
							<img src="../assets/img/assistance.svg" alt="Assistance icon" />
							<span className="txt-color-digital_mid_grey_1 bold padding_0_0_0_10" >{t("feedback.need_help_cta")}</span>
						</button>
						<span className="s_20"></span>
					</center>
				</div>
			</div>
			 <div id="modal-box-succes" className="notification-help">
                    <div id="modal-box" className={modalOpen ? "migration active" : "migration"}>
                        <div className={modalOpen ? "popup-content short bg-color-white padding_20 flex col between active" : "popup-content short bg-color-white padding_20 flex col between"}>
                            <span className="s_30"></span>
                            <div className="txt-caption-m-size bold">{t("feedback.need_help_cta")}</div>
                            <span className="s_20"></span>
                            <div className="txt-m-size bold w_100">{t("feedback.need_help_text_1")} {t("feedback.need_help_submit")}  {t("feedback.need_help_text_2")}</div>
                            <span className="s_20"></span>
                            <button className="l-size cta w_100 button-help" data-attr="ok" onClick={() => setModalOpen(false)}>OK</button>
                            <span className="s_20"></span>
                        </div>
                        <div className="popup-overlay"></div>
                    </div>
				</div>
		</section>
	);
}
export default Settings;