import React, { Suspense } from 'react'
import './App.css';
import './i18n';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import LoginPage from "./pages/LoginPage";
import LoginTokenPage from "./pages/LoginTokenPage";
import HomePage from './pages/HomePage';
import NotFoundPage from "./pages/NotFoundPage";
import FirstTimeSettingsPage from "./pages/FirstTimeSettingsPage";

function isLoggedIn() {
  const token = localStorage.getItem('token');
  return (typeof token != 'undefined' && token != null);
}

function App() {
  return (
      <Router>
         <Suspense fallback={null}>
          <Switch>
            <Route exact path="/login" render={() => (
              isLoggedIn() ? (
                <Redirect to="/"/>
                ) : (
                  <LoginPage />
              )
            )} />
            <Route exact path="/login/callback" component={LoginTokenPage} />
            <Route exact path="/account-settings" render={() => (
              !isLoggedIn() ? (
                <Redirect to="/login"/>
              ) : (
                <FirstTimeSettingsPage />
              )
            )}/>
            <Route path="/" render={() => (
              !isLoggedIn() ? (
                <Redirect to="/login"/>
              ) : (
                <HomePage />
              )
            )}/>
            <Route component={NotFoundPage} />
          </Switch>
          </Suspense>
      </Router>
  );
}

export default App;
