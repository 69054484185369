import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

function EmptyFeedbackList() {

    const { t } = useTranslation();
    const history = useHistory();

    return (
       <div className="gridContainer body_m empty_feedback_container padding_20_0 bg-color-digital_light_grey_1 flex col">
            <span></span>
            <div className="flex col center w_100">
                <img width="200" src="../assets/img/pedestrian01.svg" alt="Empty feedback" />
                <span className="s_20"></span>
                <div className="empty_feedback_container_title txt-xl-size bold">{t("received_feedback.no_feedback")}</div>
            </div>
            <div className="flex center w_100">
                <button className="fill l-size" onClick={() => { history.goBack() }}>OK</button>
            </div>
        </div>
    );
}

export default EmptyFeedbackList;