/*
 * action types
 */

export const PAGE_TITLE = 'PAGE_TITLE';
export const USER_ATTS = 'USER_ATTS';

/*
 * action creators
 */

export function changePageTitle(text) {
    return {
        type: PAGE_TITLE,
        text
    }
}
export function addUserAtts(atts) {
    return {
        type: USER_ATTS,
        atts
    }
}

