import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import useBodyClass from '../../../helpers/helper';


function LanguageSelector({ languageChange }) {


  const { t, i18n } = useTranslation();
  const [lang, setLang] = useState();
  const savedLanguage = localStorage.getItem('lang');
  const userLang = (savedLanguage != null && ['en', 'sk'].includes(savedLanguage)) ? savedLanguage : process.env.REACT_APP_DEFAULT_LANGUAGE;

  useEffect(() => {
    setLang(userLang);
  }, [userLang]);

  const changeLanguage = (event) => {
    if (['en', 'sk'].includes(event.target.value)) {
      i18n.changeLanguage(event.target.value);
      localStorage.setItem('lang', event.target.value);
      setLang(event.target.value);
      // languageChange(event.target.value);
    }
  }
  const changeStep = () => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    languageChange(lang);
  }

  useBodyClass("preview-page-language");
  return (
    <div className="preview-page-language_body flex">
      <div className="preview-page-language_body_form items col left-bottom bg-color-white">
        <span className="s_30"></span>
        <div className="preview-page-language_body_form_title txt-head-s-size flex center bold padding_20">
          <img width="45" src="../assets/img/lng_icon.png" alt="" /><span className="txt-caption-l-size bold padding_0_0_0_15"> {t("account.language_title")}</span>
        </div>
        <div className="preview-page-language_body_form_radio w_100 flex">
          <label className="container_radio txt-m-size bold w_100 flex between" htmlFor="radio1">{t("settings.language_sk")}
					            <input type="radio" id="radio1" name="language" value="sk" checked={typeof lang !== 'undefined' && lang === 'sk' ? true : false} onChange={changeLanguage} />
            <span className="checkmark"></span>
          </label>
        </div>
        <div className="preview-page-language_body_form_radio w_100 flex">
          <label className="container_radio txt-m-size bold w_100 flex between" htmlFor="radio2">{t("settings.language_en")}
					          <input type="radio" id="radio2" name="language" checked={lang === 'en' ? true : false} value="en" onChange={changeLanguage} />
            <span className="checkmark"></span>
          </label>
        </div>
        <span className="s_40"></span>
        <div className="padding_20 w_100">
          <button className="cta w_100 flex center l-size" onClick={changeStep}> {t("account.next_button")} <i className="icon icon-arrow-next txt-m-size padding_0_0_0_5"></i></button>
        </div>
        <span className="s_20"></span>
      </div>
    </div>
  );
}

export default LanguageSelector;