import React from "react";
import useBodyclassName from '../../../helpers/helper';
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


function Preloader(props) {
    const { t } = useTranslation();
  //Update Body Tag className
  useBodyclassName('preview-page-loading');
  return (
      <div id="wrapper-preview-page-loading">
           <Helmet>
            <title>
                {t(process.env.REACT_APP_APPLICATION_NAME)} | {t("login.page_title")}
            </title>
            </Helmet>
            <section className="preview-page-loading">
                <div className="preview-page-loading_header padding_20">
                <div className="preview-page-loading_header_logo">
                    <img src="/assets/img/small_logo.svg" alt="Orange logo" className="m" />
                    <img src="/assets/img/dt_logo_orange.svg" alt="Orange logo" className="dt" />
                </div>
                </div>
            
                <div className="preview-page-loading_body padding_20 flex">
                <div className="items col left-bottom">
                    <div className="preview-page-loading_body_title txt-color-white txt-head-m-size bold"><span className="txt-caption-s-size bold">Orange</span> <br/>{t(process.env.REACT_APP_APPLICATION_NAME)}</div>
                    <span className="s_40"></span>
                    <div className="preview-page-loading_body_loading flex center txt-color-digital_light_grey_5 txt-m-size bold">
                    <img src="/assets/img/loading_icon.svg" alt="Loading icon" className="rotating" /><span className="padding_0_0_0_15">{t("app.loading")}</span> 
                    </div>
                    <span className="s_40"></span> 
                </div>
                </div>
            </section>
        </div>
  );
}

export default Preloader;