import React from "react";
import Header from '../components/parts/partials/header';
import useBodyClass from '../helpers/helper';
import { Helmet } from "react-helmet";
import LoginSimpleForm from '../components/parts/login/loginSimpleForm';
import { useTranslation } from "react-i18next";


function LoginPage(props) {
  const { t } = useTranslation();


  useBodyClass("preview-page-login");

    return (
      <div id="wrapper-preview-page-login">
        <Helmet>
          <title>
            {process.env.REACT_APP_APPLICATION_NAME} | {t("login.page_title")}
          </title>
        </Helmet>
        <section className="preview-page-login">
          <Header />
          <LoginSimpleForm />
        </section>
      </div>
    );
}
export default LoginPage;