import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { changePageTitle } from "../actions/actions";

function NotFoundPage(props) {
  //Change Title
  useEffect(()=>{
    props.dispatch(changePageTitle());
  });
  return (
    <div>
      <h1> This is NotFoundPage </h1>
    </div>
  );
}

export default connect()(NotFoundPage);