import React from "react";

const LoggedOutHeader = () => {
  return (
        <div className="preview-page-login_header padding_20">
            <div className="preview-page-login_header_logo">
                <img src="../assets/img/small_logo.svg" alt="Orange logo" className="m" />
                <img src="../assets/img/dt_logo_orange.svg" alt="Orange logo" className="dt" />
            </div>
        </div>
  );
}
export default LoggedOutHeader;