import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { API_SEND_FEEDBACK_URL } from '../constants';
import axios from 'axios';
import CreateStepOne from "../components/feedbacks/createStepOne";
import CreateStepTwo from "../components/feedbacks/createStepTwo";
import { Helmet } from "react-helmet";
import { useSelector, } from "react-redux";

const getWidth = () => window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

function CreateFeedbackPage(props) {
    const history = useHistory();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [user, setUser] = useState(null);
    const [type, setType] = useState(null);
    const [step, setStep] = useState(1);
    const [anonymous, setAnonymous] = useState(0);
    const [modalShow, setModalShow] = useState(false);
    const [isSending, setIsSending] = useState(false);
    const is_online = useSelector(state => state.app.app_online);
    const stickerList = [
        'sticker_one_team',
        'sticker_responsibility',
        'sticker_trustworthiness',
        'sticker_people_centricity',
        'sticker_progressiveness'
    ];
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    useEffect(() => {
       if(!is_online){
           history.push('/');
       }
       // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [is_online])

    useEffect(() => {
        if (width <= 768) {
            dispatch({
                type: 'PAGE_TITLE',
                text: t("give_feedback.page_title"),
                back: true,
            });
        } else {
            dispatch({
                type: 'PAGE_TITLE',
                text: "Orange " + t(process.env.REACT_APP_APPLICATION_NAME),
                is_back: false,
                is_home: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, width])

    useEffect(() => {
        if (typeof props.location.state != 'undefined' && typeof props.location.state.info != 'undefined') {
            setUser(props.location.state.info);
            setAnonymous(props.location.state.info.settings.get_anonymous_feedback);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const feedbackType = (val) => {
        setType(val);
        setStep(2);
    }

    const submitFeedback = async (atts) => {
        if (isSending) return;
        setIsSending(true);
        let body = {};
        body['token'] = localStorage.getItem('token');
        body['anonymous'] = atts.anonymous;
        body['type'] = type;
        body['message'] = atts.message;
        body['received'] = user.guid;

        stickerList.map(function(item){
            body[item] = (atts.stickers.indexOf(item) !== -1) ? 1 : 0;
            return false;
        });


        await axios.post(API_SEND_FEEDBACK_URL, body).then((res) => {
            if (res.status) {
                setIsSending(false);
                setModalShow(true);
            } else {
                setIsSending(false);
                console.error('Unable to SEND FEEDBACK');
            }
        }).catch(error => {
            if (error.response.status === 403) {
                localStorage.removeItem('token');
                history.push('/login');
            } else {
                window.location.href = '/error';
                setIsSending(false);
                console.error('Unable to SEND FEEDBACK');
                console.log(error);
            }
        });
    };

    return (
        <div id="wrapper-give-form-choose">
             <Helmet>
                <title>
                    Orange {t("Openly")} | {t("breadcrumb.give_feedback")}
                </title>
            </Helmet>
            <section className="give_form_feedback">
                <div className="gridContainer body_m give_form_feedback_header ">
				    <div className="gridcontainer_widht">
                        <div className="breadcrumbs d">
                            <span className="s_30"></span>
                            <span className="txt-m-size bold flex left-center">
                                {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
                                <a href="#" onClick={() => history.push('/')}>{t('breadcrumb.homepage')}</a>  <i className="icon icon-arrow-next padding_0_10"></i>  <a href="#" onClick={() => history.push('/give-feedback')}>{t('breadcrumb.give_feedback')}</a>
                                {
                                    step === 1 ? (
                                            <span><i className="icon icon-arrow-next padding_0_10"></i> {t('breadcrumb.choose_type')}</span>
                                    ): null
                                }
                                {
                                    step === 2 ? (
                                            <span><i className="icon icon-arrow-next padding_0_10"></i> {t('breadcrumb.form')}</span>
                                    ): null
                                }
                            </span>  
                            <span className="s_40"></span>
                        </div>

                {
                    user !== null ? (
                         <div className="give_form_choose_header bg-color-white">
                            <div className="gridContainer give_form_choose_header_item flex padding_20_0 ">
                                <div className="photo-colaps padding_0_15_0_0">
                                    <div className="img-photo">
                                        <img src={typeof user.photo != 'undefined' && user.photo != null ? "data:image/png;base64," + user.photo : "../assets/img/avatars/no-avatar.png"} alt={user.name + "  profile pic"} />
                                    </div>
                                </div>
                                <div>
                                    <div className="txt-xxl-size bold">{user.name}</div>
                                    <span className="s_5"></span>
                                    <div className="txt-s-size txt-color-digital_mid_grey_1">{user.position}  |  {user.department}</div>
                                </div>
                            </div>
                        </div>
                    ) : null
                }
                  </div>
                </div>
                {
                    step === 1 ? (
                        <CreateStepOne choose={feedbackType} />
                    ) : null
                }
                {
                    step === 2 ? (
                        <CreateStepTwo choose={type} anonymous={anonymous} submit={submitFeedback}/>
                    ) : null
                }
            </section>
            <div id="modal-box-succes" className="notification-feedback-succes">
                <div id="modal-box" className={modalShow ? "migration active" : "migration"}>
                    <div className={modalShow ? "popup-content short bg-color-white padding_20 active" : "popup-content short bg-color-white padding_20"}>
                        <span className="s_40"></span>
                        <div className="flex center"><i className="icon icon-tick-circle txt-color-digital_func_green txt-head-l-size"></i></div>
                        <span className="s_20"></span>
                        <div className="flex center txt-xxl-size bold">{t("new_feedback.modal_success")}</div>
                        <span className="s_10"></span>
                        <div className="flex center">{t("new_feedback.modal_msg")}</div>
                        <span className="s_20"></span>
                        <button className="l-size cta w_100" data-attr="ok" onClick={() => history.push('/')}>{t("new_feedback.modal_ok")}</button>
                        <span className="s_20"></span>
                    </div>
                    <div className="popup-overlay"></div>
                </div>
            </div> 
        </div>

    );
}

export default CreateFeedbackPage;