import i18n from 'i18next'
import Backend from 'i18next-xhr-backend'
import {
    initReactI18next
} from 'react-i18next'

const savedLanguage = localStorage.getItem('lang');
const defaultLanguageByDomain = window.location.hostname === 'openly.orange.sk' ? 'en' : process.env.REACT_APP_DEFAULT_LANGUAGE;
const userLang = (savedLanguage != null && ['en', 'sk'].includes(savedLanguage)) ? savedLanguage : defaultLanguageByDomain;

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: userLang,
        backend: {
            /* translation file path */
            loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
        },
        fallbackLng: 'en',
        debug: false,
        /* can have multiple namespace, in case you want to divide a huge translation into smaller pieces and load them on demand */
        ns: ['translations'],
        defaultNS: 'translations',
        keySeparator: false,
        interpolation: {
            escapeValue: false,
            // formatSeparator: ','
        },
        react: {
            wait: true
        }
    })

export default i18n;