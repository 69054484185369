import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react'
import { render } from 'react-dom'
import { createStore , applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import appReducer from './reducers';
import Root from './Root';
import * as serviceWorker from './serviceWorker';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

const store = createStore(appReducer, applyMiddleware(thunk));
let persistor = persistStore(store)

render(
    (
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
           <Root store={store} />
        </PersistGate>
    </Provider>
    )
    , document.getElementById('rootApp'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
// serviceWorker.unregister();
