import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { API_GET_SENT_FEEDBACK_LIST_URL, API_USER_SEARCH_URL } from '../constants';
import axios from 'axios';
import SingleSentFeedback from "../components/feedbacks/singleSentFeedback";
import { Helmet } from "react-helmet";
// import { get, set } from "idb-keyval";
import { useSelector, } from "react-redux";

function createMarkup(text) {
    return {
        __html: text
    };
}
const getWidth = () => window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

function GiveFeedbackPage(props) {

    const [feedbackList, setFeedbackList] = useState([]);
    const [userList, setUserList] = useState([]);
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [isSending, setIsSending] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [display, setDisplay] = useState(false);
    const [showTip, setShowTip] = useState(true);
    const [showHint, setShowHint] = useState(false);
    const isMounted = useRef(true);
    const cancelToken = useRef(null);
    const [searchInput, setSearchInput] = useState('');
    const is_online = useSelector(state => state.app.app_online);

    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
        if (showHint) {
            document.body.classList.add('no-scroll');
        } else {
            document.body.classList.remove('no-scroll');
        }
    }, [showHint]);

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    useEffect(() => {
        if (width <= 768) {
            dispatch({
                type: 'PAGE_TITLE',
                text: t("give_feedback.page_title"),
                back: true,
            });
        } else {
            dispatch({
                type: 'PAGE_TITLE',
                text: "Orange " + t(process.env.REACT_APP_APPLICATION_NAME),
                is_back: false,
                is_home: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, width])

    useEffect(() => {
        const getSentFeedback = async () => {
            if (isSending) return;
            setIsSending(true);
            const isIE = /*@cc_on!@*/ false || !!document.documentMode;
            console.log('IE:'+isIE);
            // if(!isIE){
            //         await get('sent-feedbacks').then(async (val) => {
            //             if (typeof val !== 'undefined') {
            //                 setFeedbackList(val)
            //                 setDisplay(true);
            //                 setIsSending(false);
            //             } else {
            //                 let body = {};
            //                 body['token'] = localStorage.getItem('token');
            //                 await axios.post(API_GET_SENT_FEEDBACK_LIST_URL, body).then((res) => {
            //                     if (res.data) {
            //                         set('sent-feedbacks', res.data);
            //                         setFeedbackList(res.data)
            //                         setDisplay(true);
            //                         setIsSending(false);
            //                     } else {
            //                         setIsSending(false);
            //                         console.error('Unable to GET notifications');
            //                     }
            //                 }).catch(error => {
            //                     if (error.response.status === 403) {
            //                         localStorage.removeItem('token');
            //                         window.location.href = '/login';
            //                     } else {
            //                         setIsSending(false);
            //                         console.error('Unable to GET Feedback List');
            //                         console.log(error);
            //                     }
            //                 });
            //             }
            //         });
            // }else{
            let body = {};
            body['token'] = localStorage.getItem('token');
            await axios.post(API_GET_SENT_FEEDBACK_LIST_URL, body).then((res) => {
                if (res.data) {
                    setFeedbackList(res.data)
                    setDisplay(true);
                    setIsSending(false);
                } else {
                    setIsSending(false);
                    console.error('Unable to GET notifications');
                }
            }).catch(error => {
                if (error.response.status === 403) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else {
                    setIsSending(false);
                    console.error('Unable to GET Feedback List');
                    console.log(error);
                }
            });
            // }
            if (isMounted.current)
                setIsSending(false);
        };
        getSentFeedback();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);
    useEffect(() => {

        const searchPerson = async () => {
            if(!is_online) return;
            cancelToken.current = axios.CancelToken.source();
            let body = {};
            body['token'] = localStorage.getItem('token');
            body['search'] = searchInput;
            await axios.post(API_USER_SEARCH_URL, body, { cancelToken: cancelToken.current.token }).then((res) => {
                if (res.data) {
                    setUserList(res.data.data)
                    setIsSearching(false);
                    setDisplay(false);
                } else {
                    setIsSearching(false);
                    console.error('Unable to GET Person');
                }
            }).catch(error => {
                if (axios.isCancel(error)) {
                    return;
                }
                if (typeof error.response != 'undefined' && error.response.status === 403) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else {
                    setIsSearching(false);
                    console.error('Unable to GET Person');
                    console.log(error);
                }
            });
        };

        if (searchInput.length > 1) {
            if (cancelToken.current) {
                cancelToken.current.cancel();
            }
            searchPerson();
        }else{
            setUserList([]);
            setDisplay(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);
    return (
        <section className={showTip ? "give_empty_feedback" : "give_search"}>
            <Helmet>
                <title>
                    Orange {t("Openly")} | {t("breadcrumb.give_feedback")}
                </title>
            </Helmet>
            <div className={showTip ? "banner-essential_ask bg-color-digital_dark_grey_2 transition-top" : "banner-essential_ask bg-color-digital_dark_grey_2 transition-top active"}>
                <div className="gridContainer body_m banner-essential_container flex between" onClick={() => setShowHint(true)}>
                    <div>
                        <div className="txt-color-white txt-xxl-size bold m">{t("smart_tips.title_3_1")} <br />{t("smart_tips.title_3_2")}</div>
                        <div className="txt-color-white txt-xxl-size bold dt">{t("smart_tips.title_3_1")} {t("smart_tips.title_3_2")}</div>
                        <span className="s_20"></span>
                        <button className="inverted s-size" onClick={() => setShowHint(true)}>{t("smart_tips.btn_cta")}</button>
                    </div>
                    <div className="flex center">
                        <img width="80" height="95" src="../assets/img/certification.svg" alt="Megaphone icon" />
                    </div>
                </div>
            </div>
            <section className={showHint ? "drawer-smart-tips active" : "drawer-smart-tips"}>
                <div className="drawer-smart-tips_body">
                    <div className="mt">
                        <div className="osk-header_feedback flex between padding_0_20 bg-color-black">
                            <div className="osk-header_feedback_logo flex center" onClick={() => {setShowHint(false)}}>
                                <i className="icon icon-arrow-previous txt-m-size txt-color-white"></i>
                                <span className="txt-xxl-size txt-color-white padding_0_0_0_20 bold">{t("smart_tips.back")}</span>
                            </div>
                        </div>
                    </div>

                    <section className="smart-tips bg-color-digital_dark_grey_2">
                        <div className="smart-tips_container padding_0_20">
                            <span className="s_40 mt"></span>
                            <div className="txt-color-white txt-caption-m-size bold">{t("smart_tips.title_2")}</div>
                            <span className="s_40"></span>
                            <div className="smart-tips_container_item">
                                <div className="txt-color-white txt-l-size">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips_give.paragraph_1"))}/>
                                </div>
                            </div>
                            <span className="s_30"></span>
                            <div className="smart-tips_container_item">
                                <div className="txt-color-white txt-l-size">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips_give.paragraph_2"))}/>
                                </div>
                            </div>
                            <span className="s_30"></span>
                            <div className="smart-tips_container_item">
                                <div className="txt-color-white txt-l-size">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips_give.paragraph_3"))}/>
                                </div>
                            </div>
                            <span className="s_30"></span>
                            <div className="smart-tips_container_item">
                                <div className="txt-color-white txt-l-size">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips_give.paragraph_4"))}/>
                                </div>
                            </div>
                            <span className="s_30"></span>
                            <div className="smart-tips_container_item">
                                <div className="txt-color-white txt-l-size">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips_give.paragraph_5"))}/>
                                </div>
                            </div>
                            <span className="s_50"></span>
                            <button className="inverted w_100 l-size" onClick={() => {setShowHint(false)}}>{t("smart_tips.go_cta")}</button>
                            <span className="s_40 mt"></span>
                        </div>
                    </section>
                </div>
            </section>

            <div className={"gridContainer body_m give_search_container padding_20_0 bg-color-digital_light_grey_1 flex col" }>
                <div className="gridcontainer_widht">

                    <div className="breadcrumbs d">
                        <span className="s_10"></span>
                        {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
                        <span className="txt-m-size bold flex left-center"><a href="#" onClick={() => history.push('/')}>{t('breadcrumb.homepage')}</a>  <i className="icon icon-arrow-next padding_0_10"></i> {t('breadcrumb.give_feedback')}</span>
                    </div>
                    <div className="w_100">
                        {
                            is_online ? (
                                <div>
                                    <span className={ showTip && width <= 768 ? "" : "s_40"}></span>
                                    <div className={showTip && width < 768 ? "give_search_container_search flex center w_100 padding_0_20" : "give_search_container_search flex center w_100 "}>
                                        <input type="search" placeholder={t("give_feedback.placeholder")} key={'searchInput'} className="w_100" onFocus={() => {
                                            setDisplay(false); setShowTip(false);
                                        }}  onBlur={() => {setShowTip(true); setDisplay(true) }} onInput={(event) => { setSearchInput(event.target.value)}} onChange={(event) => { setSearchInput(event.target.value) }} />
                                        <button className="bg-color-white clean" ><i className="icon icon-search txt-caption-s-size txt-color-orange"></i></button>
                                    </div>
                                </div>
                            ) : null
                        }
                        {
                            feedbackList.length && !isSending && display && !userList.length ? (
                                <div className="my_feedback_container w_100">
                                    <span className="s_40"></span>
                                    <div className="my_feedback_container_title txt-xxl-size bold padding_0_20">{t("give_feedback.sent")}</div>
                                    <span className="s_25"></span>
                                    {
                                        feedbackList.map(function (item, index) {
                                            return <SingleSentFeedback key={item.guid} item={item} indexItem={index} itemList={feedbackList}/>
                                        })
                                    }
                                    <span className="s_20 mt"></span>
                                </div>
                            ) : null
                        }
                        {
                            userList.length && !isSearching ? (
                                <div className="give_search_container w_100">
                                    <span className="s_5"></span>
                                    {
                                        userList.map(function (item) {
                                            return (
                                                <div key={item.guid} className="give_search_container_result flex padding_0_0_0_20 bg-color-white" onClick={() => history.push('/new-feedback', {info: item})}>
                                                    <div className="photo-colaps padding_0_15_0_0">
                                                        <div className="img-photo">
                                                            <img src={typeof item.photo != 'undefined' && item.photo != null ? "data:image/png;base64," + item.photo : "../assets/img/avatars/no-avatar.png"} alt={item.name + "  profile pic"} />
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <div className="txt-l-size bold">{item.name}</div>
                                                        <span className="s_5"></span>
                                                        <div className="txt-m-size txt-color-digital_mid_grey_1">{item.position} | {item.department}</div>
                                                    </div>
                                                </div>
                                            )

                                        })
                                    }
                                </div>
                            ) : null
                        }
                        {/* {
                        !userList.length && !isSearching ? (
                            <div className="my_feedback_container w_100">
                                <span className="s_40"></span>
                        <div className="gridContainer txt-xxl-size bold text-center">{t("give_feedback.empty_list")}</div>
                                <span className="s_25"></span>
                            </div>
                        ) : null
                    } */}
                    </div>
                </div>
            </div>

        </section>

    );
}

export default GiveFeedbackPage;
