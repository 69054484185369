import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import SingleStickerFeedback from '../components/feedbacks/stickerSingleFeedback';
import OwlCarousel from "react-owl-carousel-autoheight";
import { API_GET_SENT_FEEDBACK_LIST_URL } from '../constants';
import axios from 'axios';
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";

const getWidth = () => window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

const SingleSentIdFeedback = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const carouselRef = useRef();
    const history = useHistory();
    const [feedbackIndex, setFeedbackIndex] = useState(null);
    const [feedbackList, setFeedbackList] = useState([]);
    let [width, setWidth] = useState(getWidth());

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    useEffect(() => {
        if (width <= 768) {
            dispatch({
                type: 'PAGE_TITLE',
                text: t("give_feedback.page_title_2"),
                back: true,
            });
        } else {
            dispatch({
                type: 'PAGE_TITLE',
                text: "Orange " + t(process.env.REACT_APP_APPLICATION_NAME),
                is_back: false,
                is_home: true,
            });
        }
         window.scrollTo(0, 0);
         // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, width])

     useEffect(() => {
         const getFeedbackList = async () => {
             let body = {};
             body['token'] = localStorage.getItem('token');
             await axios.post(API_GET_SENT_FEEDBACK_LIST_URL, body).then((res) => {
                 if (res.data) {
                     setFeedbackList(res.data);
                 } else {
                     console.error('Unable to GET feedbacks');
                 }
             }).catch(error => {
                 if (error.response.status === 403) {
                     localStorage.removeItem('token');
                     window.location.href = '/login';
                 } else {
                     window.location.href = '/error';
                     console.error('Unable to GET Feedback List');
                     console.log(error);
                 }
             });
         };
         getFeedbackList();
     }, [t]);
     useEffect(() => {
         const getCurrentIndex = () => {
             if (feedbackList.length) {
                 feedbackList.map(function (item, index) {
                     if (item.guid === props.match.params.id) {
                         setFeedbackIndex(index);
                     }
                     return false;
                 });
             }
         }
         getCurrentIndex();
         // eslint-disable-next-line react-hooks/exhaustive-deps
     }, [feedbackList]);

    const itemChanged = (index) => {
         window.scrollTo(0, 0);
        setFeedbackIndex(index);
    }
    const changePrev = () => {
        carouselRef.current['$ele'].trigger("prev.owl.carousel", 500);
    }
    const changeNext = () => {
        carouselRef.current['$ele'].trigger("next.owl.carousel", 500);
    }

    return (
        <section className="my_feedback_preview">
             <Helmet>
                <title>
                    Orange {t("Openly")} | {t("breadcrumb.sent_feedback")}
                </title>
            </Helmet>
            <div className="gridContainer body_m my_feedback_preview_container">
				    <div className="gridcontainer_widht">
                
                <div className="breadcrumbs d">
                    <div className="gridcontainer_widht">
                        <span className="s_30"></span>
                        <span className="txt-m-size bold flex left-center">
                            {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
                            <a href="#" onClick={() => history.push('/')}>{t('breadcrumb.homepage')}</a> <i className="icon icon-arrow-next padding_0_10"></i> <a href="#" onClick={() => history.push('/give-feedback')}>{t('breadcrumb.give_feedback')}</a>  <i className="icon icon-arrow-next padding_0_10"></i> {t('breadcrumb.sent_feedback')}
                        </span>  
                        <span className="s_40"></span>
                    </div>
                </div>
            {
               feedbackList.length && setFeedbackIndex !== null ? (
                    <div className="my_feedback_preview_container">
                        <div className="my_feedback_preview_button_carusel flex padding_10_0 bg-color-white">
                            <div className="bg-color-white">
                                <div className={  feedbackIndex > 0 ? "customPrevBtn s-size margin_0_10_0_0" : "customPrevBtn s-size margin_0_10_0_0 disabled"  } onClick={() => changePrev()}><i className="icon icon-arrow-previous"></i></div>
                                <div className={ feedbackIndex < feedbackList.length - 1 ? "customNextBtn s-size" : "customNextBtn s-size disabled" } onClick={() => changeNext()}><i className="icon icon-arrow-next"></i></div>
                            </div>
                        </div>
                        <OwlCarousel className="owl-theme" margin={5} autoHeight={true} items={1} dots={false} startPosition={feedbackIndex} ref={carouselRef} onChanged={(e) => {itemChanged(e.item.index)}}>
                            {
                                feedbackList.map(function(feedback, index){
                                    return (
                                        <div className="item" key={feedback.guid}>
                                            <div className="my_feedback_preview_header bg-color-white">
                                                <div className="gridContainer flex between padding_20_0">
                                                    <div className="my_feedback_preview_header_item flex padding_10_0">
                                                        <div className="photo-colaps padding_0_15_0_0">
                                                            <div className="img-photo">
                                                              <img src={feedback.received[0].photo && feedback.received[0].photo != null ? "data:image/png;base64," + feedback.received[0].photo : "../assets/img/avatars/no-avatar.png"} alt={feedback.received[0].name + "  profile pic"} />
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <div className="txt-l-size bold">{feedback.received[0].name}</div>
                                                            <span className="s_5"></span>
                                                            <div className="txt-s-size txt-color-digital_mid_grey_1">{feedback.received[0].position}  |  {feedback.received[0].department}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="gridContainer">
                                                <span className="s_20"></span>
                                                        {
                                                        feedback.type === 'appreciation' ? (
                                                            <div className="txt-caption-s-size bold flex left-center"><i className="icon icon-Medal padding_0_5_0_0"></i> <span>{t("new_feedback.appreciation")}</span> </div>
                                                        ) : null
                                                    }
                                                    {
                                                        feedback.type === 'improvement' ? (
                                                            <div className="txt-caption-s-size bold flex left-center"><i className="icon icon-edashboard padding_0_5_0_0"></i> <span>{t("new_feedback.improvement")}</span> </div>
                                                        ) : null
                                                    }
                                                <span className="s_20"></span>
                                                    {
                                                        feedback.message ? (
                                                            <div className=" my_feedback_preview_container_text padding_0_0_0_20">
                                                                <span className="txt-l-size bold">{feedback.message}</span>
                                                                <span className="s_20"></span>
                                                                <div className="txt-m-size txt-color-digital_mid_grey_1">{feedback.created}</div>
                                                            </div>
                                                        ) : null
                                                    }
                                                    {
                                                        !feedback.message ? (
                                                            <div className="txt-m-size txt-color-digital_mid_grey_1">{feedback.created}</div>
                                                        ): null
                                                    }
                                                <span className="s_20"></span>
                                            </div>


                                             {
                                                (feedback.stickers) ? (
                                                    <div className="bg-color-white w_100">
                                                        <div className="gridContainer padding_10_0">
                                                            <span className="s_30"></span>
                                                            <div className="txt-xl-size bold">{t("new_feedback.received_stickers")}</div>
                                                            <span className="s_10"></span>
                                                            <div className="flex between wrap">
                                                                {

                                                                    feedback.stickers ? (
                                                                        Object.keys(feedback.stickers).map(function (item, index) {
                                                                             // eslint-disable-next-line
                                                                            if (feedback.stickers[item] == 1) {
                                                                                return (
                                                                                    <div key={index} className="my_feedback_preview_container_item_cart padding_20 w_45 flex col center">
                                                                                        <SingleStickerFeedback stickername={item} />
                                                                                        <div className="photo-text txt-m-size bold">{t(item)}</div>
                                                                                    </div>
                                                                                )
                                                                            }
                                                                            return false;
                                                                        })
                                                                    ) : null
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                                                ) : null 
                                            }
                                            {
                                                 feedback.anonymous ? (
                                                        <div>
                                                            <span className="s_20"></span>
                                                            <span className="txt-m-size txt-color-digital_mid_grey_1">{t('feedback.anonymous_sent')}</span>
                                                        </div>
                                                    ) : null
                                            }
                                        </div>

                                    )
                                })
                            }
                        </OwlCarousel>
                    </div>
                ) : null
            }
                </div>
            </div>
        </section>
    )
}
export default SingleSentIdFeedback;