import React from "react";
import { useTranslation } from "react-i18next";

function Sticker({stickername, counter, type}) {

    const { t } = useTranslation();
    return (
        <div key={stickername + "_" + type} className={counter ? "my_feedback_container_item_cart active padding_20 w_100 flex col center" : "my_feedback_container_item_cart padding_20 w_100 flex col center"}>
            <div className="photo-colaps">
                <div className="img-photo bg-color-cta flex center">
                    <img src={"../assets/img/stickers/"+t(stickername + '_img').replace(' ', '') + ".svg"} alt={t(stickername)+ " Icon"}/>
                    {
                       <div className={type === 'green' ? "img-photo_num bg-color-digital_func_green txt-color-white flex center bold" : "img-photo_num bg-color-digital_func_yellow flex center bold"}>{counter ? counter : 0}</div>
                    }
                </div>
                <span className="s_15"></span>
            </div>
            <div className="photo-text txt-m-size bold">{t(stickername)}</div>
                <span className="s_5"></span>
        </div>
    );
}

export default Sticker;