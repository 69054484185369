import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from '../components/parts/partials/header';
import DashboardPage from './DashboardPage';
import GiveFeedbackPage from './GiveFeedbackPage';
import SmartTipsPage from './SmartTipsPage';
import ReceivedFeedbackPage from './ReceivedFeedbackPage';
import SingleFeedback from './SingleFeedback';
import SingleIdFeedback from './SingleIdFeedback';
import SingleSentFeedback from './SingleSentFeedback';
import SingleSentIdFeedback from './SingleSentIdFeedback';
import CreateFeedbackPage from './CreateFeedbackPage';
import ErrorPage from "./ErrorPage";

function HomePage() {
  return (
    <div>
      <Header />
      <Router>
        <Switch>
              <Route exact path="/" component={DashboardPage}/>
              <Route exact path="/error" component={ErrorPage}/>
              <Route exact path="/give-feedback" component={GiveFeedbackPage}/>
              <Route exact path="/smart-tips" component={SmartTipsPage}/>
              <Route exact path="/received-feeback" component={ReceivedFeedbackPage}/>
              <Route exact path="/new-feedback" component={CreateFeedbackPage}/>
              <Route exact path="/feedback/:id" component={SingleFeedback}/>
              <Route exact path="/notif-feedback/:id" component={SingleIdFeedback}/>
              <Route exact path="/notif-myfeedback/:id" component={SingleSentIdFeedback}/>
              <Route exact path="/sent-feedback/:id" component={SingleSentFeedback}/>
              <Route component={ErrorPage} />
        </Switch>
      </Router>
    </div>
  );
}

export default HomePage;