import React, { useState, useEffect} from "react";
import Notifications from './notificationList';
import Settings from './settings';
import { useHistory } from "react-router-dom";


const getWidth = () => window.innerWidth ||
	document.documentElement.clientWidth ||
	document.body.clientWidth;

const LoggedInHeader = ({ title, is_back ,is_home, notificationsCount}) => {
	const [showNotification, setShowNotification] = useState(false);
	const [showSettings, setShowSettings] = useState(false);
	const history = useHistory();
	let [width, setWidth] = useState(getWidth());
	
	useEffect(() => {
		let timeoutId = null;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => setWidth(getWidth()), 150);
		};
		window.addEventListener('resize', resizeListener);

		return () => {
			window.removeEventListener('resize', resizeListener);
		}
	}, [])

	return (
		<div>
			<div className="osk-header bg-color-black flex center">
				<div className="gridContainer body_l flex between">
					{!is_home && (is_back && width <= 768) ? (
						<div className="osk-header_feedback_logo flex center">
							<i className="icon icon-arrow-previous txt-m-size txt-color-white" onClick={() => { history.goBack() }}></i>
							<span className="txt-xxl-size txt-color-white padding_0_0_0_20 bold"  onClick={() => { history.goBack() }}>{title}</span>
						</div>
					) : (
							<div className="osk-header_logo flex center"  onClick={() => { window.location.href = '/' }} >
								<img src="../assets/img/small_logo.svg" alt="Orange logo" className="mt"  />
								<img src="../assets/img/dt_logo_orange.svg" alt="Orange logo" className="d" />
								<span className="txt-xxl-size txt-color-white padding_0_0_0_20 bold">{title}</span>	
							</div>
					)}
					<div className="flex center">
						<div data-attr="open-settings" className="items center osk-header_settings padding_0_20_0_0" onClick={() =>  {setShowNotification(false); setShowSettings(true)}}>
							<i className="icon icon-settings txt-caption-s-size txt-color-white"></i>
						</div>
						<div className="items center osk-header_notification" onClick={() => {setShowSettings(false); setShowNotification(true)}}>
							<div>
								<i className="icon icon-Notification-Bell txt-caption-s-size txt-color-white"></i>
								{
									notificationsCount ? (
									<div className="osk-header_notification_num bg-color-digital_func_green txt-color-white flex center">{notificationsCount}</div>
									) : null
								}
							</div>
						</div>
					</div>
				</div>
			</div>
			<Notifications show={showNotification} close={() => setShowNotification(false)} />
			<Settings show={showSettings} close={() => setShowSettings(false)} />
			<div className={showNotification || showSettings ? "drawer-overlay active" : "drawer-overlay"} onClick={() => {setShowNotification(false); setShowSettings(false)}}></div>
		</div>
	);
}
export default LoggedInHeader;