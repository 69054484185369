export const API_URL = process.env.REACT_APP_APPLICATION_API_URL;

//AUTH ROUTES
export const API_AUTH_URL = API_URL + '/auth';
export const API_AUTH_CALLBACK_URL = API_URL + '/auth/callback';
export const AD_AUTH_URL = API_URL + '/login';
export const IDP_AUTH_URL = API_URL + '/login';
export const API_LOGOUT_URL = API_URL + '/logout';
//USER SETTINGS
export const API_USER_INFO_URL = API_URL + '/user/info';
export const API_SET_USER_SETTINGS_POST_URL = API_URL + '/user/settings/set';
export const API_GET_USER_SETTINGS_POST_URL = API_URL + '/user/settings/get';
export const API_USER_SEARCH_URL = API_URL + '/user/search';
//USER NOTIFICATIONS
export const API_GET_USER_NOTIFICATIONS_LIST_URL = API_URL + '/user/notifications/get';
export const API_READ_USER_NOTIFICATIONS_LIST_URL = API_URL + '/user/notifications/read';
export const API_DELETE_USER_NOTIFICATIONS_LIST_URL = API_URL + '/user/notifications/delete';
export const API_GET_USER_NOTIFICATIONS_COUNT_URL = API_URL + '/user/notifications/get/new';
//USER FEEDBACKS
export const API_GET_USER_FEEDBACK_SINGLE_URL = API_URL + '/feedback/one';
export const API_GET_USER_FEEDBACK_LIST_URL = API_URL + '/feedback/received';
export const API_GET_SENT_FEEDBACK_LIST_URL = API_URL + '/feedback/sent';
export const API_READ_FEEDBACK_URL = API_URL + '/feedback/read';
export const API_GET_USER_STICKER_LIST_URL = API_URL + '/feedback/summary';
export const API_SEND_FEEDBACK_URL = API_URL + '/feedback/add';
export const API_EXPORT_FEEDBACK_URL = API_URL + '/feedback/export';
export const API_THANK_YOU_FEEDBACK_URL = API_URL + '/feedback/thank-you';
export const API_COFFEE_FEEDBACK_URL = API_URL + '/feedback/inviteForCoffee';
//HOW TO
export const API_HOW_TO_URL = API_URL + '/howto/get';
export const API_READ_HOW_TO_URL = API_URL + '/howto/read';