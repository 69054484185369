import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import StickerSingleFeedback from '../feedbacks/stickerSingleFeedback';


function CreateStepTwo({ choose, anonymous, submit }) {
    const { t } = useTranslation();
    const [selectedStickers, setSelectedStickers] = useState([]);
    const [message, setMessage] = useState(null);
    const [anonymousCheckbox, setAnonymousCheckbox] = useState(0);
    const textRef = useRef();
    const stickerList = [
        'sticker_one_team',
        'sticker_responsibility',
        'sticker_trustworthiness',
        'sticker_people_centricity',
        'sticker_progressiveness'
    ];
    const stickerMargin = [
        'margin_0_5_5_0',
        'margin_0_0_5_0',
        'margin_0_5_5_0',
        'margin_0_0_5_0',
        'margin_0_5_5_0',
    ]

    const feedbackStickerClick = (val) => {
        if(selectedStickers.indexOf(val) === -1){
            setSelectedStickers(setSelectedStickers => [...setSelectedStickers, val]);
        }
        else{
            setSelectedStickers(selectedStickers.filter(item => item !== val));
        }
    }

    const submitFeedback = () => {
        submit({
            message: message,
            stickers : selectedStickers,
            anonymous: anonymousCheckbox,
        });
    }

    return (
        <section className="">
            <span className="s_40"></span>
            <div className="gridContainer body_m flex">
                <div className="gridcontainer_widht flex">
                    <div className="flex center">
                        {
                            choose === 'appreciation' ? (
                                <i className="icon icon-Medal txt-head-s-size padding_0_15_0_0"></i>
                            ) :
                                (
                                    <i className="icon icon-edashboard txt-head-s-size padding_0_15_0_0"></i>
                                )
                        }
                        <div className="txt-caption-m-size bold">{choose === 'appreciation' ? t('new_feedback.appreciation') : t('new_feedback.improvement')}</div>
                    </div>
                </div>
            </div>
            {
                anonymous ? (
                    <div className="gridContainer body_m">
                        <div className="gridcontainer_widht">
                             <span className="s_40"></span>
                                <label className="container_checkbox bold">
                                {t('new_feedback.anonymous')}
                                <input type="checkbox" onChange={() => setAnonymousCheckbox(anonymousCheckbox ? 0 : 1)} />
                                <span className="checkmark_checkbox"></span>
                            </label>
                        </div>
                    </div>
                ) : null
            }
            <div className="gridContainer body_m give_form_feedback_container padding_20_0 padding_20_0">
                <div className="gridcontainer_widht">
                    <div className="bold flex">{t('new_feedback.write')} <span className="txt-color-digital_light_grey_4">&nbsp;{t("new_feedback.optional")}</span></div>
                    <span className="s_10"></span>
                    <textarea ref={textRef} name="textarea" id="textarea" rows="4" placeholder="" className="w_100" onChange={(e) => {
                        const target = e.target;
                        textRef.current.style.height = "92px";
                        textRef.current.style.height = `${target.scrollHeight}px`;
                        setMessage(e.target.value)
                        }}></textarea>
                </div>
            </div>

            <div className="gridContainer body_m">
                <div className="gridcontainer_widht">
                    <div className="txt-l-size bold">{t('new_feedback.select')} <span className="txt-color-digital_light_grey_4">&nbsp;{t("new_feedback.optional")}</span></div>
                </div>
            </div>

            <div className="gridContainer body_m give_form_feedback_container flex padding_20_0">
                <div className="gridcontainer_widht flex wrap">
                    {
                    stickerList.map(function (item, index) {
                        return (
                            <div key={item} className={(selectedStickers.indexOf(item) !== -1) ? "give_form_feedback_container_item bg-color-white flex col center-top padding_20 active check " + stickerMargin[index] : "give_form_feedback_container_item bg-color-white flex col center-top padding_20 " + stickerMargin[index]} onClick={() => feedbackStickerClick(item)}>
                                <StickerSingleFeedback stickername={item} />
                                 <div className="photo-text txt-m-size bold">{t(item)}</div>
                                <div className="give_form_feedback_container_item_check"><i className="icon icon-tick-circle txt-color-digital_func_green txt-xxl-size"></i></div>
                            </div>
                        )
                    })
                    }                   
                </div>
            </div>

            {
                selectedStickers.length || (message !== null && message.length) ? (
                    <div className="gridContainer body_m button_send_feedback paddgin_20_0">
                        <div className="gridcontainer_widht">
                            <span className="s_20"></span>
                                <button className="l-size cta padding_20_0" data-attr="modal-box-feedback-succes" onClick={submitFeedback}> {t('new_feedback.send_cta')}</button>
                            <span className="s_20"></span>
                            <span className="s_20 d"></span>
                        </div>
				     </div>
                ) : null
            }
        </section>

    )
}

export default CreateStepTwo;