import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { API_READ_FEEDBACK_URL, API_THANK_YOU_FEEDBACK_URL, API_GET_USER_FEEDBACK_LIST_URL, API_COFFEE_FEEDBACK_URL } from '../constants';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import SingleStickerFeedback from '../components/feedbacks/stickerSingleFeedback';
import OwlCarousel from "react-owl-carousel-autoheight";
import { Helmet } from "react-helmet";

const getWidth = () => window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

const SingleIdFeedback = (props) => {
    const { t } = useTranslation();
    const userInfo = useSelector(state => state.user);
    const dispatch = useDispatch();
    const carouselRef = useRef();
    const textRef = useRef();
    const [currentItemIndex, setCurrentItemIndex] = useState(null);
    const [message, setMessage] = useState('');
    const [showThankYou, setShowThankYou] = useState(false);
    const [showCoffeeModal, setShowCoffeeModal] = useState(false);
    const [thankYouText, setThankYouText] = useState(null);
    const [inviteTo, setInviteTo] = useState(null);
    const [feedbackIndex, setFeedbackIndex] = useState(null);
    const [feedbackList, setFeedbackList] = useState([]);
    const history = useHistory();
    let [width, setWidth] = useState(getWidth());
    const is_online = useSelector(state => state.app.app_online);

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])

    useEffect(() => {
        if (width <= 768) {
            dispatch({
                type: 'PAGE_TITLE',
                text: t("new_feedback.page_title_2"),
                back: true,
            });
        } else {
            dispatch({
                type: 'PAGE_TITLE',
                text: "Orange " + t(process.env.REACT_APP_APPLICATION_NAME),
                is_back: false,
                is_home: true,
            });
        }
        window.scrollTo(0, 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, width])

    useEffect(() => {
        if (!showCoffeeModal) {
            setMessage('');
        }
    }, [showCoffeeModal]);
    useEffect(() => {
        if (showThankYou) {
            setTimeout(() => {
                setShowThankYou(false)
            }, 3000);
        }
    }, [showThankYou]);

    useEffect(() => {
        const getFeedbackList = async () => {
            let body = {};
            body['token'] = localStorage.getItem('token');
            await axios.post(API_GET_USER_FEEDBACK_LIST_URL, body).then((res) => {
                if (res.data) {
                    setFeedbackList(res.data);
                } else {
                    console.error('Unable to GET feedbacks');
                }
            }).catch(error => {
                if (error.response.status === 403) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else {
                    window.location.href = '/error';
                    console.error('Unable to GET Feedback List');
                    console.log(error);
                }
            });
        };
        getFeedbackList();
    }, [t]);
    useEffect(() => {
        const getCurrentIndex = () => {
            if(feedbackList.length){
                feedbackList.map(function(item, index){
                    if(item.guid === props.match.params.id){
                        setFeedbackIndex(index);
                    }
                    return false;
                });
            }
        }
        getCurrentIndex();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedbackList]);


    const thankYouBtn = async (index) => {
        if (!is_online)
            return false;
        let body = {};
        body['token'] = localStorage.getItem('token');
        let feedbackItem = feedbackList[index];
        if (feedbackItem.thanked)
            return false;
        feedbackItem.thanked = 1;
        await axios.post(API_THANK_YOU_FEEDBACK_URL + '/' + feedbackItem.guid + '/' + feedbackItem.sent[0].guid, body).then((res) => {
            if (res.data.status) {
                setThankYouText(t("new_feedback.thank_you_message"));
                setShowThankYou(true);
            } else {
                window.location.href = '/error';
                console.error("UNABLE TO SEND THANK YOU");
            }
        }).catch(error => {
            if (error.status === 403) {
                localStorage.removeItem('token');
                history.push('/login');
            } else {
                window.location.href = '/error';
                console.error("UNABLE TO SEND THANK YOU")
                console.log(error);
            }
        });

    }

    const coffeeBtn = async () => {
        if(!message.length || !is_online)
            return false;

        let body = {};
        body['token'] = localStorage.getItem('token');
        body['subject'] = t("invite_coffee.default_subject");
        body['body'] = message;
        let feedbackItem = feedbackList[currentItemIndex];
        if (feedbackItem.invited)
            return false;
        feedbackItem.invited = 1;
        await axios.post(API_COFFEE_FEEDBACK_URL + '/' + feedbackItem.guid + '/' + feedbackItem.sent[0].guid, body).then((res) => {
            if (res.data.status) {
                setThankYouText(t("new_feedback.coffee_message"))
                setShowCoffeeModal(false);
                setShowThankYou(true);
            } else {
                window.location.href = '/error';
                console.error("UNABLE TO SEND INVITE YOU");
            }
        }).catch(error => {
            if (error.status === 403) {
                localStorage.removeItem('token');
                history.push('/login');
            } else {
                window.location.href = '/error';
                console.error("UNABLE TO SEND INVITE YOU")
                console.log(error);
            }
        });
    }
    const coffeeBtnModal = async (index) => {
        if (!is_online)
            return false;
        let feedbackItem = feedbackList[index];
        if (feedbackItem.invited)
            return false;
        setCurrentItemIndex(index);
        setInviteTo(feedbackItem.sent[0].email);
        setShowCoffeeModal(true);
    }

    const markAsRead = async (feedbackID) => {
        if (!is_online) return;
        let body = {};
        body['token'] = localStorage.getItem('token');
        await axios.post(API_READ_FEEDBACK_URL + '/' + feedbackID, body).then((res) => { }).catch(error => {
            return false;
        });

    };
    const itemChanged = (index) => {
        if (typeof feedbackList[index] != 'undefined' && !feedbackList[index].read){
            markAsRead(feedbackList[index].guid);
        }
        window.scrollTo(0, 0);
        setFeedbackIndex(index);
    }
    const changePrev = () => {
        carouselRef.current['$ele'].trigger("prev.owl.carousel", 500);
    }
    const changeNext = () => {
        carouselRef.current['$ele'].trigger("next.owl.carousel", 500);
    }
    return (
        <section className="my_feedback_preview">
            <Helmet>
                <title>
                    Orange {t("Openly")} | {t("breadcrumb.received_feedback")}
                </title>
            </Helmet>
            <div className="gridContainer body_m my_feedback_preview_container">
                <div className="gridcontainer_widht">

                    <div className="breadcrumbs d">
                        <div className="gridcontainer_widht">

                            <span className="s_30"></span>
                            <span className="txt-m-size bold flex left-center">
                        {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
                                <a href="#" onClick={() => history.push('/')}>{t('breadcrumb.homepage')}</a>  <i className="icon icon-arrow-next padding_0_10"></i> <a href="#" onClick={() => history.push('/received-feeback')}>{t('breadcrumb.received_feedback')}</a> <i className="icon icon-arrow-next padding_0_10"></i> {t('breadcrumb.detail')}
                    </span>
                            <span className="s_40"></span>
                        </div>
                    </div>
                    {
                        feedbackList.length && feedbackIndex !== null && !showCoffeeModal ? (
                            <div className="my_feedback_preview_container">
                                <div className="my_feedback_preview_button_carusel flex padding_10_0 bg-color-white">
                                    <div className="bg-color-white">
                                        <div className={  feedbackIndex > 0 ? "customPrevBtn s-size margin_0_10_0_0" : "customPrevBtn s-size margin_0_10_0_0 disabled"  } onClick={() => changePrev()}><i className="icon icon-arrow-previous"></i></div>
                                        <div className={ feedbackIndex < feedbackList.length - 1 ? "customNextBtn s-size" : "customNextBtn s-size disabled" } onClick={() => changeNext()}><i className="icon icon-arrow-next"></i></div>
                                    </div>
                                </div>
                                <OwlCarousel className="owl-theme" margin={5} autoHeight={true} items={1} dots={false} startPosition={feedbackIndex} ref={carouselRef} onChanged={(e) => {itemChanged(e.item.index)}}>
                                    {
                                        feedbackList.map(function(feedback, index){
                                            return (
                                                <div className="item" key={feedback.guid}>
                                                    <div className="my_feedback_preview_header bg-color-white">
                                                        <div className="gridContainer flex between padding_20_0">
                                                            <div className="my_feedback_preview_header_item flex padding_10_0">
                                                                <div className="photo-colaps padding_0_15_0_0">
                                                                    <div className="img-photo">
                                                                        <img src={!feedback.anonymous && feedback.sent[0].photo && feedback.sent[0].photo != null ? "data:image/png;base64," + feedback.sent[0].photo : "../assets/img/avatars/no-avatar.png"} alt={feedback.sent[0].name + "  profile pic"} />
                                                                    </div>
                                                                </div>

                                                                {
                                                                    feedback.anonymous ? (
                                                                        <div>
                                                                            <div className="txt-l-size bold">{t("new_feedback.anonymous_user")}</div>
                                                                            <span className="s_5"></span>
                                                                        </div>
                                                                    ) : (
                                                                        <div>
                                                                            <div className="txt-l-size bold">{feedback.sent[0].name}</div>
                                                                            <span className="s_5"></span>
                                                                            <div className="txt-s-size txt-color-digital_mid_grey_1">{feedback.sent[0].position}  |  {feedback.sent[0].department}</div>
                                                                        </div>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="gridContainer">
                                                        <span className="s_20"></span>
                                                        {
                                                            feedback.type === 'appreciation' ? (
                                                                <div className="txt-caption-s-size bold flex left-center"><i className="icon icon-Medal padding_0_5_0_0"></i> <span>{t("new_feedback.appreciation")}</span> </div>
                                                            ) : null
                                                        }
                                                        {
                                                            feedback.type === 'improvement' ? (
                                                                <div className="txt-caption-s-size bold flex left-center"><i className="icon icon-edashboard padding_0_5_0_0"></i> <span>{t("new_feedback.improvement")}</span> </div>
                                                            ) : null
                                                        }
                                                        <span className="s_20"></span>
                                                        {
                                                            feedback.message ? (
                                                                <div className=" my_feedback_preview_container_text padding_0_0_0_20">
                                                                    <span className="txt-l-size bold">{feedback.message}</span>
                                                                    <span className="s_20"></span>
                                                                    <div className="txt-m-size txt-color-digital_mid_grey_1">{feedback.created}</div>
                                                                </div>
                                                            ) : null
                                                        }
                                                        {
                                                            !feedback.message ? (
                                                                <div className="txt-m-size txt-color-digital_mid_grey_1">{feedback.created}</div>
                                                            ): null
                                                        }
                                                        <span className="s_20"></span>
                                                    </div>
                                                    {
                                                        (feedback.stickers) ? (
                                                            <div className="bg-color-white w_100">
                                                                <div className="gridContainer padding_10_0">
                                                                    <span className="s_30"></span>
                                                                    <div className="txt-xl-size bold">{t("new_feedback.received_stickers")}</div>
                                                                    <span className="s_10"></span>
                                                                    <div className="flex between wrap">
                                                                        {

                                                                            feedback.stickers ? (
                                                                                Object.keys(feedback.stickers).map(function (item, index) {
                                                                                    // eslint-disable-next-line
                                                                                    if (feedback.stickers[item] == 1) {
                                                                                        return (
                                                                                            <div key={index} className="my_feedback_preview_container_item_cart padding_20 w_45 flex col center">
                                                                                                <SingleStickerFeedback stickername={item} />
                                                                                                <div className="photo-text txt-m-size bold">{t(item)}</div>
                                                                                            </div>
                                                                                        )
                                                                                    }
                                                                                    return false;
                                                                                })
                                                                            ) : null
                                                                        }
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                    {
                                                        !feedback.anonymous && is_online ? (
                                                            <div className={width <= 768 ? "gridContainer flex padding_30_0" : "flex padding_30_0"}>
                                                                <button className={feedback.thanked ? "w_50 m-size cta margin_0_10_0_0 disabled" : "w_50 m-size cta margin_0_10_0_0"} data-attr="modal-box-notification" onClick={() => { thankYouBtn(index) }}>{t("new_feedback.thank_you_cta")}</button>
                                                                <button className={feedback.invited ? "w_50 m-size disabled" : "w_50 m-size"} onClick={() => { coffeeBtnModal(index) }}>{t("new_feedback.invite_cta")}</button>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>

                                            )
                                        })
                                    }
                                </OwlCarousel>
                            </div>
                        ) : null
                    }
                    {
                        showCoffeeModal && feedbackIndex !== null ? (
                            <div className="item" key={feedbackList[feedbackIndex].guid}>
                                <div className="my_feedback_preview_header bg-color-white">
                                    <div className="gridContainer flex between padding_20_0">
                                        <div className="my_feedback_preview_header_item flex padding_10_0">
                                            <div className="photo-colaps padding_0_15_0_0">
                                                <div className="img-photo">
                                                    <img src={!feedbackList[feedbackIndex].anonymous && feedbackList[feedbackIndex].sent[0].photo && feedbackList[feedbackIndex].sent[0].photo != null ? "data:image/png;base64," + feedbackList[feedbackIndex].sent[0].photo : "../assets/img/avatars/no-avatar.png"} alt={feedbackList[feedbackIndex].sent[0].name + "  profile pic"} />
                                                </div>
                                            </div>

                                            {
                                                feedbackList[feedbackIndex].anonymous ? (
                                                    <div>
                                                        <div className="txt-l-size bold">{t("new_feedback.anonymous_user")}</div>
                                                        <span className="s_5"></span>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <div className="txt-l-size bold">{feedbackList[feedbackIndex].sent[0].name}</div>
                                                        <span className="s_5"></span>
                                                        <div className="txt-s-size txt-color-digital_mid_grey_1">{feedbackList[feedbackIndex].sent[0].position}  |  {feedbackList[feedbackIndex].sent[0].department}</div>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="gridContainer">
                                    <span className="s_20"></span>
                                    {
                                        feedbackList[feedbackIndex].type === 'appreciation' ? (
                                            <div className="txt-caption-s-size bold flex left-center"><i className="icon icon-Medal padding_0_5_0_0"></i> <span>{t("new_feedback.appreciation")}</span> </div>
                                        ) : null
                                    }
                                    {
                                        feedbackList[feedbackIndex].type === 'improvement' ? (
                                            <div className="txt-caption-s-size bold flex left-center"><i className="icon icon-edashboard padding_0_5_0_0"></i> <span>{t("new_feedback.improvement")}</span> </div>
                                        ) : null
                                    }
                                    <span className="s_20"></span>
                                    {
                                        feedbackList[feedbackIndex].message ? (
                                            <div className=" my_feedback_preview_container_text padding_0_0_0_20">
                                                <span className="txt-l-size bold">{feedbackList[feedbackIndex].message}</span>
                                                <span className="s_20"></span>
                                                <div className="txt-m-size txt-color-digital_mid_grey_1">{feedbackList[feedbackIndex].created}</div>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        !feedbackList[feedbackIndex].message ? (
                                            <div className="txt-m-size txt-color-digital_mid_grey_1">{feedbackList[feedbackIndex].created}</div>
                                        ): null
                                    }
                                    <span className="s_20"></span>
                                </div>
                                {
                                    (feedbackList[feedbackIndex].stickers) ? (
                                        <div className="bg-color-white w_100">
                                            <div className="gridContainer padding_10_0">
                                                <span className="s_30"></span>
                                                <div className="txt-xl-size bold">{t("new_feedback.received_stickers")}</div>
                                                <span className="s_10"></span>
                                                <div className="flex between wrap">
                                                    {

                                                        feedbackList[feedbackIndex].stickers ? (
                                                            Object.keys(feedbackList[feedbackIndex].stickers).map(function (item, index) {
                                                                // eslint-disable-next-line
                                                                if (feedbackList[feedbackIndex].stickers[item] == 1) {
                                                                    return (
                                                                        <div key={index} className="my_feedback_preview_container_item_cart padding_20 w_45 flex col center">
                                                                            <SingleStickerFeedback stickername={item} />
                                                                            <div className="photo-text txt-m-size bold">{t(item)}</div>
                                                                        </div>
                                                                    )
                                                                }
                                                                return false;
                                                            })
                                                        ) : null
                                                    }
                                                </div>

                                            </div>
                                        </div>
                                    ) : null
                                }
                            </div>
                        ): null
                    }
                    <div id="modal-box-succes" className="notification-succes">
                        <div id="modal-box" className={showThankYou ? "migration active" : "migration"}>
                            <div className={showThankYou ? "popup-content short notification flex left-center padding_0_10 active" : "popup-content short notification flex left-center padding_0_10"}>
                                <div className="popup-close" data-popup-role="close" onClick={() => {setShowThankYou(false)}}>×</div>

                                <div className="flex left-center padding_0_15_0_0">
                                    <div><img src="../assets/img/succes-icon.svg" alt="" /></div>
                                    <div className="txt-color-white txt-m-size bold padding_0_10_0_10">{thankYouText}</div>
                                </div>
                            </div>
                            <div className="popup-overlay" onClick={() => {setShowThankYou(false)}}></div>
                        </div>
                    </div>
                    <div id="modal-box-succes" className="invite-coffee">
                        <div id="modal-box" className={showCoffeeModal ? "migration active" : "migration"}>
                            <div className={showCoffeeModal ? "popup-content short bg-color-white padding_20 flex col between active" : "popup-content short bg-color-white padding_20 flex col between" }>
                                <div className="w_100">
                                    <div className="flex left-center">
                                        <label htmlFor="from" className="w_20 txt-m-size bold">{t("invite_coffee.from")}: </label>
                                        <input type="text" id="from" value={userInfo.email} className="w_80" readOnly />
                                    </div>
                                    <span className="s_10"></span>

                                    <div className="flex left-center">
                                        <label htmlFor="to" className="w_20 txt-m-size bold">{t("invite_coffee.to")}: </label>
                                        <input type="text" id="to" value={inviteTo ? inviteTo : ''} className="w_80" readOnly />
                                    </div>
                                    <span className="s_10"></span>

                                    <div className="flex left-top">
                                        <label htmlFor="message" className="w_20 txt-m-size bold">{t("invite_coffee.subject")}: </label>
                                        <textarea type="text" id="input3" className="w_80 textarea_1" readOnly value={t("invite_coffee.default_subject")}></textarea>
                                    </div>
                                    <span className="s_10"></span>

                                    <textarea ref={textRef} id="coffee_body" rows="8" className="w_100" value={message} placeholder={t("invite_coffee.placeholder")} onChange={(e) => {
                                        setMessage(e.target.value)
                                    }}>
                                    </textarea>
                                    <span className="s_10"></span>
                                    <button className={message.length && is_online ? "cta" : "cta disabled"}  onClick={() => {coffeeBtn()}}>{t("invite_coffee.cta")}</button>
                                </div>
                            </div>
                            <div className="popup-overlay" onClick={() => {setShowCoffeeModal(false)}}></div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default SingleIdFeedback;
