import React from "react";
import { useTranslation } from "react-i18next";

function createMarkup(text) {
    return {
        __html: text
    };
}

function CreateStepOne({ choose }) {
    const { t } = useTranslation();

    return (

        <div className="gridContainer body_m give_form_choose_body">
            <div className="gridcontainer_widht">
                <span className="s_20"></span>
                <div className="txt-l-size bold">{t("new_feedback.feedback_choose")}</div>
                <span className="s_20"></span>
                <div className="give_form_choose_container flex">
                    <div className="give_form_choose_container_item flex col center w_100 padding_20 bg-color-white margin_0_5_0_0">
                        <div><i className="icon icon-Medal txt-head-m-size"></i></div>
                        <span className="s_15"></span>
                        <div className="txt-m-size bold" ><div dangerouslySetInnerHTML={createMarkup(t("new_feedback.feedback_appreciation"))}/></div>
                        <span className="s_15"></span>
                        <button className="cta s-size w_100" onClick={() => choose('appreciation')}>{t("new_feedback.select_cta")}</button>
                    </div>
                    <div className="give_form_choose_container_item flex col center w_100 padding_20 bg-color-white">
                        <div><i className="icon icon-edashboard txt-head-m-size"></i></div>
                        <span className="s_15"></span>
                        <div className="txt-m-size bold"><div dangerouslySetInnerHTML={createMarkup(t("new_feedback.feedback_improvement"))}/></div>
                        <span className="s_15"></span>
                        <button className="cta s-size w_100" onClick={() => choose('improvement')}>{t("new_feedback.select_cta")}</button>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default CreateStepOne;