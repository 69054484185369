import React from "react";
import { useTranslation } from "react-i18next";
import {IDP_AUTH_URL} from '../../../constants';
import axios from 'axios';


function LoginSimpleForm({ errorMessage }) {
  const { t } = useTranslation();

  const handleLoginButton =  async () => {
     await axios.post(IDP_AUTH_URL).then((res) => {
          if (res.data.redirect_url) {
             window.location.href = res.data.redirect_url;
          } 
        }).catch((error) => {
          console.log(error);
        });
  };

  return (
      <div className="preview-page-login_body padding_20 flex w_100">
        <div className="items col left-bottom">
          <div className="preview-page-login_body_title txt-color-white txt-head-m-size bold"><span className="txt-caption-s-size bold">Orange</span> <br/>{t("Openly")}</div>
          <span className="s_40"></span>
          <div className="preview-page-login_body_loading flex center txt-color-digital_light_grey_5 txt-m-size bold w_100">
            <button className="cta inverted w_100 l-size" onClick={handleLoginButton}>{t("login.form.submit")}</button>
          </div>
          <span className="s_40"></span> 
        </div>
      </div>
  );
}

export default LoginSimpleForm;