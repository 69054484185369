import React from "react";
import Header from '../components/parts/partials/header';
import useBodyClass from '../helpers/helper';
import {Helmet} from "react-helmet";
import LanguageSelector from "../components/parts/login/languageSelector";
import AnonymusFeedback from "../components/parts/login/anonymusFeedback";
import Preloader from '../components/parts/preloader/preloader';
import { useState, useEffect, useRef, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory} from "react-router-dom";
import { API_SET_USER_SETTINGS_POST_URL} from '../constants';
import axios from "axios";
 

function FirstTimeSettingsPage(props) {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const isMounted = useRef(true);
  const { t } = useTranslation();
  const history = useHistory();
  useEffect(() => {
    setStep(1);
  }, []);

  const languageChanger = (val) => {
    submitSettings('language', val).then(() => {
      setStep(2);
    });
  };
  const feedbackChanger = (val) => {
    submitSettings('get_anonymous_feedback', val).then(() => {
      setLoading(false);
      setIsSending(false);
      history.push("/");
    });
  };
  const submitSettings = useCallback(async (key, value) => {
    if (isSending) return;
    setLoading(true);
    setIsSending(true);
    let body = {};
    body[key] = value;
    body['token'] = localStorage.getItem('token');
    axios.post(API_SET_USER_SETTINGS_POST_URL, body).then((res) => {
      if (res.data.status) {
        setLoading(false);
        setIsSending(false);
        return true;
      } else {
        console.error('Unable to POST settings');
      }
      setLoading(false);
    });
    if (isMounted.current)
      setIsSending(false);
  }, [isSending]);


  useBodyClass("preview-page-login");

  if (loading) {
    return <Preloader />;
  } else {
    return (
      <div id="wrapper-preview-page-login">
        <Helmet>
          <title>
            {process.env.REACT_APP_APPLICATION_NAME} | {t("account.page_title")}
          </title>
        </Helmet>
        <section className="preview-page-login">
          <Header />
          {step === 1 ? (
            <LanguageSelector languageChange={languageChanger} />
          ) : null}
          {step === 2 ? <AnonymusFeedback confirm={submitSettings} change={feedbackChanger} /> : null}
        </section>
      </div>
    );
  }
}
export default FirstTimeSettingsPage;