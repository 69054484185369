import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { API_GET_USER_NOTIFICATIONS_LIST_URL, API_DELETE_USER_NOTIFICATIONS_LIST_URL, API_READ_USER_NOTIFICATIONS_LIST_URL } from '../../../../constants';
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';

const Notifications = ({ show, close }) => {
    const { t } = useTranslation();
    const [notifications, setNotifications] = useState([]);
    const dispatch = useDispatch();
    const isMounted = useRef(true);
    const [isSending, setIsSending] = useState(false);
    const [showDelete, setShowDelete] = useState(true);
    const history = useHistory();
    const is_online = useSelector(state => state.app.app_online);
    useEffect(() => {
        const handleProps = async () => {
            if (!show) {
                dispatch({
                    type: 'USER_NOTIFICATION_NUMBER',
                    notifications: 0,
                });
                if (isSending) return;
                setIsSending(true);

                let body = {};
                body['token'] = localStorage.getItem('token');
                await axios.post(API_GET_USER_NOTIFICATIONS_LIST_URL, body).then((res) => {
                    if (res.data) {
                        setIsSending(false);
                        setNotifications(res.data);
                        markAsRead();
                    } else {
                        setIsSending(false);
                        console.error('Unable to GET notifications');
                    }
                }).catch(error => {
                    if (error.response.status === 403) {
                        localStorage.removeItem('token');
                        history.push('/login');
                    } else {
                        setIsSending(false);
                        console.error('Unable to GET notifications');
                        console.log(error);
                        setNotifications([]);
                    }
                });

            }

            if (isMounted.current)
                setIsSending(false);
        };

        const markAsRead = async () => {
            let body = {};
            body['token'] = localStorage.getItem('token');
            await axios.post(API_READ_USER_NOTIFICATIONS_LIST_URL, body).then((res) => {
            }).catch(error => {
                return false;
            });
        };
        // handleProps();
        return () => {
            handleProps();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [show, is_online]);

    const deleteNotifications = async() => {
        let body = {};
        body['token'] = localStorage.getItem('token');
        //  del('notifications');
        await axios.post(API_DELETE_USER_NOTIFICATIONS_LIST_URL, body).then((res) => {
            if (res.data) {
                setNotifications([]);
                setShowDelete(false);
            } else {
                console.error('Unable to GET notifications');
            }
        }).catch(error => {
            if (error.response.status === 403) {
                localStorage.removeItem('token');
                history.push('/login');
            } else {
                console.error('Unable to GET notifications');
                console.log(error);
            }
        });
    }
    const clickNotification = (item) =>{
        if(item.type === 'feedback'){
            window.location.href = '/notif-feedback/' + item.parent_id;
        }
        if(item.type === 'how_to'){
            window.location.href = '/?tips=' + item.parent_id;
        }
        if (item.type === 'thank_you' || item.type === 'invite_for_coffee') {
            window.location.href = '/notif-myfeedback/' + item.parent_id;
        }
    }

    return (
        <section className={show ? "drawer_notification active" : "drawer_notification"}>
            <div className="osk-header_notification_page flex between padding_0_20 bg-color-white">

                <div className="osk-header_notification_page_logo flex center">
                    <i className="icon icon-arrow-previous txt-m-size" onClick={() => close()}></i>
                    <span className="txt-xxl-size txt-color-black padding_0_0_0_20 bold" onClick={() => close()} >{t("notifications.page_title")}</span>
                </div>
                <div className="osk-header_notification_page_clear bold txt-m-size">
                    {
                        showDelete && notifications.length && is_online ? (
                            <a href="##" onClick={() => deleteNotifications()}>{t("notifications.delete")}</a>
                        ) : null
                    }
                </div>
            </div>

            {
                notifications.length && !isSending ? (
                    <section className="notification-content">
                        <div className="notification-content_container text-left">
                            {
                                notifications.map(function (item, index) {
                                    return (
                                        <div onClick={() => { clickNotification(item)}} key={index + "_" + item.sent[0].guid} className={item.read ? "notification-content_container_item flex padding_20" : "notification-content_container_item flex padding_20 unread"}>
                                            {
                                                item.type !== 'how_to' ? (
                                                    <div className="photo-colaps padding_0_15_0_0">
                                                        <div className="img-photo">
                                                            <img src={item.sent[0].photo && item.sent[0].photo != null ? "data:image/png;base64," + item.sent[0].photo : "../assets/img/avatars/no-avatar.png"} alt={item.sent[0].name} />
                                                        </div>
                                                    </div>
                                                ) : null
                                            }
                                            <div>
                                                {
                                                    item.type !== 'how_to' ? (
                                                        <div>
                                                            <div className="txt-m-size">{item.sent[0].name !== 'Anonymous' ? item.sent[0].name : t('new_feedback.anonymous_user')} <span className="notification-content_container_item_message">{t("notify."+ item.type)}</span>  </div>
                                                            {
                                                                item.sent[0].name !== 'Anonymous' ? (
                                                                    <div>
                                                                        <span className="s_5"></span>
                                                                        <div className="txt-s-size txt-color-digital_mid_grey_1">{item.sent[0].position} | {item.sent[0].department}</div>
                                                                    </div>
                                                                ): null
                                                            }
                                                        </div>
                                                    ) : (
                                                        <div className="txt-m-size"><span className="notification-content_container_item_message">{t("notify."+ item.type)}: <span className="bold">"{item.title}"</span></span>  </div>
                                                    )
                                                }
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </section>
                ) : null
            }
            {
                !notifications.length && !isSending ? (
                    <section className="notification-empty active">
                        <div className="notification-empty_container flex col center padding_20">
                            <div>
                                <span className="s_60"></span>
                                <span className="s_60"></span>
                                <img width="200" src="../assets/img/pedestrian01.svg" alt="" />
                                <span className="s_30"></span>
                                <div className="txt-xl-size bold center">{t("notifications.empty")}</div>
                            </div>
                            <button className="cta w_100 l-size" onClick={() => close()}>{t("notifications.ok_btn")}</button>
                        </div>
                    </section>
                ) : null
            }
        </section>
    );
}
export default Notifications;
