import React, { useEffect, createRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addUserAtts } from "../actions/actions";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { API_HOW_TO_URL, API_READ_HOW_TO_URL, API_GET_USER_SETTINGS_POST_URL, API_USER_INFO_URL} from '../constants';
import axios from 'axios';
import queryString from 'query-string';
import { Helmet } from "react-helmet";
import { set, get } from "idb-keyval";


function DashboardPage(props) {
  const userInfo = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [tips, setTips] = useState(null);
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [elRefs, setElRefs] = useState([]);
  const [howToGet, setHowToGet] = useState(null);
  const is_online = useSelector(state => state.app.app_online);

    useEffect(() => {
      setHowToGet(queryString.parse(props.location.search));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(() => {
      dispatch({
        type: 'PAGE_TITLE',
        text: "Orange " + t(process.env.REACT_APP_APPLICATION_NAME),
        is_back: false,
        is_home: true,
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    useEffect(() => {
      
      if(howToGet !== null && howToGet.tips && elRefs.length){
          if (typeof elRefs[howToGet.tips] != 'undefined')
            scrollToRef(elRefs[howToGet.tips]);
      }

    }, [howToGet, elRefs]);
    

  useEffect(() => {
    const getHowTo = async () => {
      if(!is_online){
        get('tips').then((val) => {
          if (typeof val !== 'undefined'){
            setTips(val);
          }
        });
        return;
      }
      let body = {};
      body['token'] = localStorage.getItem('token');
      await axios.post(API_HOW_TO_URL, body).then((res) => {
        if (res.data) {
          setTips(res.data);
          set('tips', res.data);
        } else {
          console.error('Unable to GET Tips');
        }
      }).catch(error => {
        if (error.status === 403) {
          localStorage.removeItem('token');
          history.push('/login');
        } else {
          // window.location.href = '/error';
          // setFeedback(example);
          console.log(error);
        }
      });
    };
    const getSettings = async () => {
      if(!is_online) return;
      let body = {};
      let lang = localStorage.getItem('lang');
      body['token'] = localStorage.getItem('token');
      await axios.post(API_GET_USER_SETTINGS_POST_URL, body).then((res) => {
        if (res.data[0]) {
          if (typeof lang != 'undefined' || lang !== null || lang !== res.data[0].language){
            localStorage.setItem('lang',res.data[0].language);
            i18n.changeLanguage(res.data[0].language);
          }
        } else {
          console.error('Unable to GET USER INFO');
        }
      }).catch(error => {
        if (error.status === 403) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        } else {
          // window.location.href = '/error';
          console.log(error);
        }
      });
    };
    const getUserName = async () => {
       if (!is_online) return;
      let body = {};
      body['token'] = localStorage.getItem('token');
      await axios.post(API_USER_INFO_URL, body).then((res) => {
        if (res.data) {
          dispatch(
            addUserAtts({
              name: res.data.data.name,
              email: res.data.data.email,
            })
          );
        } else {
          console.error('Unable to GET USER INFO');
        }
      }).catch(error => {
        if (error.status === 403) {
          localStorage.removeItem('token');
          window.location.href = '/login';
        } else {
          // window.location.href = '/error';
          console.log(error);
        }
      });
    };
    getHowTo();
    getSettings();
    if(!userInfo.name){
      getUserName();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
      const tipsRefsAssign = () => {
        if (tips && tips.length) {
          let refsArray = [];
          tips.map(function(item, index){
              refsArray[item.id] = createRef(item.title);
              return false;
          });
          setElRefs(refsArray);
        }
      };
      tipsRefsAssign();
  }, [tips])

  const tipClick = async (item) => {
    let body = {};
     body['token'] = localStorage.getItem('token');
     await axios.post(API_READ_HOW_TO_URL + '/' + item.id, body).then((res) => {
       window.open(item.link, "_blank")
     }).catch(error => {
       window.open(item.link, "_blank")
     });
  }
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);
  return (
    <div id="wrapper">
      <Helmet>
        <title>
          Orange {t("Openly")} | {t("breadcrumb.homepage")}
        </title>
      </Helmet>
      <div id="wrapper-settings-language">
        <section className="homepage-content">
          <div className="gridContainer body_m">
            <div className="gridcontainer_widht">
              <div>
              <span className="s_40"></span>
              <div className="menu_title txt-caption-m-size bold">{t("feedback.greeting")} {userInfo.name.split(' ')[0]}</div>
              <span className="s_30"></span>
              <div className="feedback flex center">
                <div className="feedback_give flex col center padding_20 margin_0_5_0_0 bg-color-white w_100" onClick={() => { history.push('/give-feedback') }}>
                  <img width="64" src="../assets/img/give-feedback-to-a-colleague.svg" alt="Icon Give Fedback" />
                  <span className="s_20"></span>
                  <div className="txt-l-size bold">{t("feedback.give_feedback")}</div>
                </div>
                <div className="feedback_my flex col center padding_20 bg-color-white w_100" onClick={() => { history.push('/received-feeback') }}>
                  <div className="feedback_my_notification">
                    <i className="icon icon-Health-patient txt-head-l-size txt-color-orange"></i>
                    {
                      userInfo.feedbacks ? (
                        <div className="feedback_my_notification_num bg-color-digital_func_green txt-color-white flex center bold">{userInfo.feedbacks}</div>
                      ) : null
                    }
                  </div>
                  <span className="s_20"></span>
                  <div className="txt-l-size bold">{t("feedback.check_feedback")}</div>
                </div>
              </div>
              <span className="s_30"></span>
            
          </div>
         {
           typeof tips != 'undefined' && tips !== null ? (
              <div className="homepage-content_news">
                <div className="homepage-content_header_title txt-caption-m-size bold">{t("feedback.tips_title")}</div>
                <span className="s_30"></span>
                <div className="homepage-content_news_container">
                    {
                     Object.keys(tips).map(function(item){
                          return (
                            <div ref={elRefs[tips[item].id]} key={tips[item].id} onClick={() => tipClick(tips[item]) } >
                              <div className={!tips[item].read ? "homepage-content_news_container_item flex left-center unread" : "homepage-content_news_container_item flex left-center"}>
                                {
                                  tips[item].picture !== null ? (
                                    <div className="homepage-content_news_container_item_img margin_0_20_0_0" style={{ background: "url(data:image/png;base64," + tips[item].picture + ")"}}></div>
                                  ): (
                                      <div className="homepage-content_news_container_item_img margin_0_20_0_0" style={{ background: "url(../assets/img/news/news1.jpg)" }}></div>
                                  )
                                }
                              
                                <div className="homepage-content_news_container_item_text flex col left-center">
                                  <div className="txt-xl-size bold">{tips[item].title}</div>
                                  <span className="s_5"></span>
                                    <div className="homepage-content_news_container_item_text_desc txt-m-size txt-color-digital_mid_grey_3 bold">
                                    {tips[item].text}
                                  </div>
                                </div>
                              </div>
                              <span className="s_15"></span>
                            </div>
                          )
                      })
                    }
                </div>
              </div>
           ) : null
         }
          </div>
          </div>
          <div className="footer-link bg-color-digital_light_grey_1 padding_20_0">
            <div className="gridContainer body_m d">
                <button className="clean flex center" data-attr="modal-box-help" onClick={() => setModalOpen(true)}><img src="../assets/img/assistance.svg" alt="Assistance icon" />
                  <span className="txt-color-digital_mid_grey_1 bold padding_0_0_0_10">{t("feedback.need_help_cta")}</span></button>
                <span className="s_10"></span>
            </div>
            <div className="gridContainer body_m mt">
              <center>
                <button className="clean flex center" data-attr="modal-box-help" onClick={() => setModalOpen(true)}><img src="../assets/img/assistance.svg" alt="Assistance icon" />
                  <span className="txt-color-digital_mid_grey_1 bold padding_0_0_0_10">{t("feedback.need_help_cta")}</span></button>
              </center>
                <span className="s_20"></span>
            </div>
          </div>
        </section>
      </div>
      <div id="modal-box-succes" className="notification-help">
        <div id="modal-box" className={modalOpen ? "migration active" : "migration"}>
          <div className={modalOpen ? "popup-content short bg-color-white padding_20 flex col between active" : "popup-content short bg-color-white padding_20 flex col between"}>
            <span className="s_30"></span>
            <div className="txt-caption-m-size bold">{t("feedback.need_help_cta")}</div>
            <span className="s_20"></span>
            <div className="txt-m-size bold w_100">{t("feedback.need_help_text_1")} {t("feedback.need_help_submit")} {t("feedback.need_help_text_2")}</div>
            <span className="s_20"></span>
            <button className="l-size cta w_100 button-help" data-attr="ok" onClick={() => setModalOpen(false)}>OK</button>
            <span className="s_20"></span>
          </div>
          <div className="popup-overlay"></div>
        </div>
      </div>
    </div>
  );
}

export default DashboardPage;