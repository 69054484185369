import React, {useEffect, useRef, useState} from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PropTypes from 'prop-types';
import LoggedInHeader from './header/loggedInHeader';
import LoggedOutHeader from './header/loggedOutHeader';
import { useSelector, } from "react-redux";
import { API_GET_USER_NOTIFICATIONS_COUNT_URL } from '../../../constants';
import axios from 'axios';
import { useDispatch } from "react-redux";
// import { set } from 'idb-keyval';

const Header = () => {
 const pagetitle = useSelector(state => state.app.page_title);
 const is_back_btn = useSelector(state => state.app.is_back_btn);
 const is_home = useSelector(state => state.app.is_home_page);
 const is_online = useSelector(state => state.app.app_online);
 const userNotificationsNumber = useSelector(state => state.user.notifications);
 // const userFeedbacksNumber = useSelector(state => state.user.feedbacks);
 const dispatch = useDispatch();
const isMounted = useRef(true);
const [isSending, setIsSending] = useState(false);
const token = localStorage.getItem('token');

const updateNetwork = () => {
   dispatch({
       type: 'APP_ONLINE',
       isOnline: window.navigator.onLine
   })
};

useEffect(() => {
        window.addEventListener("offline", updateNetwork);
        window.addEventListener("online", updateNetwork);
        return () => {
            window.removeEventListener("offline", updateNetwork);
            window.removeEventListener("online", updateNetwork);
        };
});

 useEffect(() => {
     const interval = setInterval( async () => {

        updateNetwork();

         if (isSending || typeof token === 'undefined' || token === null || !is_online) return;
                setIsSending(true);
                let body = {};
                body['token'] = localStorage.getItem('token');
                await axios.post(API_GET_USER_NOTIFICATIONS_COUNT_URL, body ).then((res) => {
                    if (res.data) {
                        dispatch({
                            type: 'USER_NOTIFICATION_NUMBER',
                            notifications: res.data.notifications,
                        });

                        dispatch({
                            type: 'USER_FEEDBACK_NUMBER',
                              feedbacks: res.data.feedback,
                          });

                        // if (res.data.notifications !== userNotificationsNumber || res.data.notifications > 0) {
                        //     getNotifications();
                        // }
                        // if (res.data.feedback !== userFeedbacksNumber || res.data.feedback > 0) {
                        //     getFeedbackList();
                        //     getFeedbackStickerList();
                        // }

                        setIsSending(false);
                    } else {
                        setIsSending(false);
                        console.error('Unable to GET NOTIFICATIONS NUMBER');
                    }
                }).catch(error => {
                    setIsSending(false);
                    if (error.response.status === 403) {
                        localStorage.removeItem('token');
                         window.location.href = '/login';
                    }
                    console.error('Unable to GET NOTIFICATIONS NUMBER');
                    console.log(error);
                });
            if (isMounted.current)
                setIsSending(false);
     }, 3000);

     // const getFeedbackList = async () => {
     //     let body = {};
     //     body['token'] = localStorage.getItem('token');
     //     await axios.post(API_GET_USER_FEEDBACK_LIST_URL, body).then((res) => {
     //         if (res.data) {
     //             set('feedbacks', res.data);
     //         } else {
     //             console.error('Unable to GET notifications');
     //         }
     //     }).catch(error => {
     //         if (error.response.status === 403) {
     //             localStorage.removeItem('token');
     //             window.location.href = '/login';
     //         } else {
     //             window.location.href = '/login';
     //             console.error('Unable to GET Feedback List');
     //             console.log(error);
     //         }
     //     });
     // };
     // const getFeedbackStickerList = async () => {
     //     let body = {};
     //     body['token'] = localStorage.getItem('token');
     //     await axios.post(API_GET_USER_STICKER_LIST_URL, body).then((res) => {
     //         if (res.data) {
     //             set('stickers', res.data);
     //         } else {
     //             setIsSending(false);
     //             console.error('Unable to GET notifications');
     //         }
     //     }).catch(error => {
     //         if (error.response.status === 403) {
     //             localStorage.removeItem('token');
     //              window.location.href = '/login';
     //         } else {
     //             window.location.href = '/login';
     //             console.error('Unable to Feedback Feedback List');
     //             console.log(error);
     //         }
     //     });
     // };

     // const getNotifications = async () => {
     //    let body = {};
     //    body['token'] = localStorage.getItem('token');
     //    await axios.post(API_GET_USER_NOTIFICATIONS_LIST_URL, body).then((res) => {
     //        if (res.data) {
     //            set('notifications', res.data);
     //        } else {
     //            console.error('Unable to GET notifications');
     //        }
     //    }).catch(error => {
     //        if (error.response.status === 403) {
     //            localStorage.removeItem('token');
     //            window.location.href = '/login';
     //        } else {
     //            console.error('Unable to GET notifications');
     //            console.log(error);
     //        }
     //    });
     // };

     return () => clearInterval(interval);
     // eslint-disable-next-line react-hooks/exhaustive-deps
 }, [is_online]);

  return (
       <Router>
        <Switch>
            <Route exact path="/login" render={() => {
               return <LoggedOutHeader />
            }} />
            <Route exact path="/login/callback" render={() => {
               return <LoggedOutHeader />
            }} />
            <Route exact path="/account-settings" render={() => {
               return <LoggedOutHeader />
            }} />
            <Route render={() => {
                return <LoggedInHeader title={pagetitle} is_back={is_back_btn} is_home={is_home} notificationsCount={userNotificationsNumber}/>
            }} />
        </Switch>
      </Router>
  );
}
Header.prototype={
    title: PropTypes.string.isRequired,
}

export default Header;