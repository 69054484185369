import React from "react";
import { useTranslation } from "react-i18next";

function StickerSingleFeedback({ stickername }) {
    const { t } = useTranslation();
    return (
        <div className="photo-colaps">
            <div className="img-photo bg-color-cta flex center">
                <img src={"../assets/img/stickers/" + t(stickername + '_img').replace(' ', '') + ".svg"} alt={t(stickername) + " Icon"} />
            </div>
            <span className="s_15"></span>
        </div>
    );
}

export default StickerSingleFeedback;