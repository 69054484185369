import {combineReducers} from 'redux'
import { appOptions } from './app';
import { userOptions } from './user';
import { persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

const rootPersistConfig = {
  key: 'root',
  storage: storage,
  whitelist: ['app','user']
}

const appReducer = combineReducers({
  app:appOptions,
  user:userOptions
})

export default persistReducer(rootPersistConfig, appReducer)