import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import useBodyclassName from '../../../helpers/helper';


function AnonymusFeedback({ confirm, change }) {

	const [feedback, setFeedback] = useState(true);
	const { t } = useTranslation();

	const changeFeedback = (event) => {
		setFeedback(feedback ? false : true);
	}
	const confirmHandle = () => {
		let value = feedback ? 1: 0;
		change(value);
	}

	useBodyclassName("preview-page-language");
	return (
		<div className="preview-page-anonymous_body flex">

			<div className="preview-page-anonymous_body_form items col left-bottom bg-color-white">
				<span className="s_30"></span>
				<div className="preview-page-anonymous_body_form_title txt-head-s-size flex left-top bold padding_20">
					<img width="45" src="../assets/img/fashion.svg" alt="" className="padding_5_0_0_0" /><span className="txt-caption-l-size bold padding_0_0_0_15 w_80">{t("account.anonymous_title")}</span>
				</div>

				<div className="preview-page-anonymous_body_form_radio w_100 flex">
					<label className="container_checkbox txt-m-size bold">
						{t("account.anonymous_msg")}
						<input type="checkbox" defaultChecked={feedback ? true : false} onChange={changeFeedback} />
						<span className="checkmark_checkbox"></span>
					</label>
				</div>
				<span className="s_60"></span>
				<span className="s_20"></span>
				<div className="padding_20 w_100">
					<button className="cta w_100 flex center l-size" onClick={confirmHandle}>{t("account.anonymous_button")}</button>
				</div>
				<span className="s_20"></span>
			</div>

		</div>
	);
}

export default AnonymusFeedback;