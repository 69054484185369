import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useBodyClass from '../helpers/helper';
import { useHistory } from "react-router-dom";


function createMarkup(text) {
    return { __html: text };
}

function SmartTipsPage(props) {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    useEffect(() => {
        dispatch({
            type: 'PAGE_TITLE',
            text: t("smart_tips.back"),
            back: true,
        });
    }, [dispatch, t]);
    useBodyClass("body_smart-tips");
    return (
        <section className="smart-tips bg-color-digital_dark_grey_2">

            <div className="gridContainer smart-tips_container">
                <span className="s_40"></span>
                <div className="txt-color-white txt-caption-m-size bold">{t("smart_tips.title")}</div>
                <span className="s_40"></span>
                <div className="smart-tips_container_item">
                    <span className="s_10"></span>
                    <div className="txt-color-white txt-l-size">
                         <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_1"))}/>
                    </div>
                </div>
                <span className="s_20"></span>
                <div className="smart-tips_container_item">
                    <div className="txt-color-white txt-l-size">
                        <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_2"))} />
				      </div>
                </div>
                <span className="s_20"></span>
                <div className="smart-tips_container_item">
                    <div className="txt-color-white txt-l-size">
                        <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_3"))} />
				      </div>
                </div>
                <span className="s_20"></span>
                <div className="smart-tips_container_item">
                    <div className="txt-color-white txt-l-size">
                        <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_4"))} />
				      </div>
                </div>
                <span className="s_20"></span>
                <div className="smart-tips_container_item">
				      <div className="txt-color-white txt-l-size">
                        <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_5"))} />
				      </div>
                </div>
                <span className="s_40"></span>
                <span className="s_50"></span>
                <button className="inverted w_100" onClick={() => {history.goBack()}}>OK</button>
                <span className="s_40"></span>
            </div>

        </section>

    );
}

export default SmartTipsPage;