export const appOptions = (state = {
    page_title: '',
    is_back_btn: false,
    is_home_page: true,
    app_online: true,
}, action) => {
    switch (action.type) {
        case 'PAGE_TITLE':
            return {
                ...state,
                page_title: action.text,
                is_back_btn: action.back,
                is_home_page: action.is_home,
            }
        case 'APP_ONLINE':
            return {
                  ...state,
                  app_online: action.isOnline,
            }
         default:
             return state;
    }
};