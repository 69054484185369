export const userOptions = (state = {
    name: '',
    email: '',
    notifications: 0,
    feedbacks: 0,
}, action) => {
    switch (action.type) {
        case 'USER_ATTS':
            return {
                ...state,
                name: action.atts.name,
                email: action.atts.email
            }
        case 'USER_NOTIFICATION_NUMBER':
            return {
                ...state,
                notifications: action.notifications
            }
        case 'USER_FEEDBACK_NUMBER':
            return {
                ...state,
                feedbacks: action.feedbacks
            }
        default:
            return state;
    }
};