import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

function SingleFeedback({item, indexItem, itemList}) {

    const { t } = useTranslation();
     const history = useHistory();
    return (
        <div key={item.guid}>
            <div className="my_feedback_container_item  bg-color-white padding_0_20" onClick={() => history.push('/feedback/'+ item.guid, {list: itemList, index: indexItem})}>
                <span className="s_20"></span>
                <div className="flex between">
                    <div className="flex left-center">
                        <div className="photo-colaps padding_0_15_0_0">
                            <div className="img-photo">
                                <img src={item.sent[0].photo && item.sent[0].photo != null ? "data:image/png;base64," + item.sent[0].photo : "../assets/img/avatars/no-avatar.png"} alt={item.sent[0].name + "  profile pic"} />
                            </div>
                        </div>
                        <div className="my_feedback_container_item_desc">
                            {
                                item.anonymous ? (
                                    <div>
                                        <div className="my_feedback_container_item_desc_name txt-l-size bold">{t("new_feedback.anonymous_user")}</div>
                                        <span className="s_5"></span>
                                    </div>
                                ) : (
                                        <div>
                                            <div className="my_feedback_container_item_desc_name txt-l-size bold">{item.sent[0].name}</div>
                                            <span className="s_5"></span>
                                            <div className=" my_feedback_container_item_desc_position txt-s-size txt-color-digital_mid_grey_1">{item.sent[0].position}  |  {item.sent[0].department}</div>
                                        </div>
                                    )
                            }

                            <span className="s_10"></span>
                            {
                                item.type === 'appreciation' ? (
                                    <div className="my_feedback_container_item_desc_badge txt-m-size bold flex left-center"><i className="icon icon-Medal txt-xl-size padding_0_5_0_0"></i> <span>{t("new_feedback.appreciation")}</span> </div>
                                ) : null
                            }
                            {
                                item.type === 'improvement' ? (
                                    <div className="my_feedback_container_item_desc_badge txt-m-size bold flex left-center"><i className="icon icon-edashboard txt-xl-size padding_0_5_0_0"></i> <span>{t("new_feedback.improvement")}</span> </div>
                                ) : null
                            }
                        </div>
                    </div>

                    <div className="flex center"><i className="icon icon-arrow-next txt-color-orange txt-xxl-size"></i></div>
                </div>

                        <div className="flex right-center txt-s-size txt-color-digital_mid_grey_1">{item.created}</div>
                <span className="s_10"></span>
            </div>
            <span className="s_5"></span>
            
        </div>
    );
}

export default SingleFeedback;