import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from 'axios';
import { API_GET_USER_STICKER_LIST_URL, API_GET_USER_FEEDBACK_LIST_URL, API_EXPORT_FEEDBACK_URL } from '../constants';
import EmptyFeedbackList from '../components/feedbacks/emptyFeedbackList';
import Sticker from '../components/feedbacks/sticker';
import SingleFeedback from '../components/feedbacks/singleFeedback';
import { Helmet } from "react-helmet";
// import { get, set } from "idb-keyval";
import { useSelector, } from "react-redux";

function createMarkup(text) {
    return {
        __html: text
    };
}

const getWidth = () => window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

function ReceivedFeedbackPage(props) {

    // const userInfo = useSelector(state => state.user);
    const [feedbackList, setFeedbackList] = useState([]);
    const [unreadFeedbacks, setUnreadFeedbacks] = useState(false);
    const [stickerList, setStickerList] = useState(null);
    const [apreciationStickerList, setAprectiationStickerList] = useState([]);
    const [improvementStickerList, setImprovementStickerList] = useState([]);
    const [showHint, setShowHint] = useState(false);
    const [exportFinish, setExportFinish] = useState(false);
    const [tabActive, setTabActive] = useState('feedback');
    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const isMounted = useRef(true);
    const [isSending, setIsSending] = useState(false);
    const [isSendingStickers, setIsSendingStickers] = useState(false);
    let [width, setWidth] = useState(getWidth());
    const is_online = useSelector(state => state.app.app_online);

    useEffect(() => {
        if(exportFinish) {
            setTimeout(() => { setExportFinish(false)}, 3000);
        }
    }, [exportFinish]);
    useEffect(() => {
        if(showHint){
            document.body.classList.add('no-scroll');
        }else{
            document.body.classList.remove('no-scroll');
        }
    }, [showHint]);

    useEffect(() => {
        let timeoutId = null;
        const resizeListener = () => {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => setWidth(getWidth()), 150);
        };
        window.addEventListener('resize', resizeListener);

        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    }, [])
    useEffect(() => {
        if (width <= 768) {
            dispatch({
                type: 'PAGE_TITLE',
                text: t("new_feedback.page_title_2"),
                back: true,
            });
        } else {
            dispatch({
                type: 'PAGE_TITLE',
                text: "Orange " + t(process.env.REACT_APP_APPLICATION_NAME),
                is_back: false,
                is_home: true,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, width])

    useEffect(() => {
        const getFeedbackList = async () => {
            if (isSending) return;
            setIsSending(true);

            // await get('feedbacks').then(async (val) => {
            //     if (typeof val !== 'undefined' && val.length) {
            //          setFeedbackList(val)
            //          setIsSending(false);
            //     } else {

            //     }
            // });
            if (!is_online) {
                setFeedbackList([]);
                setIsSending(false);
                return false;
            }
            let body = {};
            body['token'] = localStorage.getItem('token');
            await axios.post(API_GET_USER_FEEDBACK_LIST_URL, body).then((res) => {
                if (res.data) {
                    //  set('feedbacks', res.data);
                    setFeedbackList(res.data)
                    setIsSending(false);
                } else {
                    setIsSending(false);
                    console.error('Unable to GET notifications');
                }
            }).catch(error => {
                if (error.response.status === 403) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else {
                    window.location.href = '/login';
                    setIsSending(false);
                    setFeedbackList([]);
                    console.error('Unable to GET Feedback List');
                    console.log(error);
                }
            });

            if (isMounted.current)
                setIsSending(false);
        };
        const getFeedbackStickerList = async () => {
            if (isSendingStickers) return;

            setIsSendingStickers(true);
            const isIE = /*@cc_on!@*/ false || !!document.documentMode;
            console.log('IE:' + isIE);
            //  if (!isIE) {
            //  await get('stickers').then(async (val) => {
            //      if (typeof val !== 'undefined') {
            //         setStickerList(val);
            //         setIsSendingStickers(false);
            //      } else {
            //           if (!is_online) {
            //                setStickerList([]);
            //                setIsSendingStickers(false);
            //               return false;
            //           }
            //           let body = {};
            //           body['token'] = localStorage.getItem('token');
            //           await axios.post(API_GET_USER_STICKER_LIST_URL, body).then((res) => {
            //               if (res.data) {
            //                   set('stickers', res.data);
            //                   setStickerList(res.data);
            //                   setIsSendingStickers(false);
            //               } else {
            //                   setIsSending(false);
            //                   console.error('Unable to GET notifications');
            //               }
            //           }).catch(error => {
            //               if (error.response.status === 403) {
            //                   localStorage.removeItem('token');
            //                   window.location.href = '/login';
            //               } else {
            //                   window.location.href = '/login';
            //                   setIsSendingStickers(false);
            //                   console.error('Unable to Feedback Feedback List');
            //                   console.log(error);
            //               }
            //           });
            //      }
            //  });
            // }else{
            let body = {};
            body['token'] = localStorage.getItem('token');
            await axios.post(API_GET_USER_STICKER_LIST_URL, body).then((res) => {
                if (res.data) {
                    setStickerList(res.data);
                    setIsSendingStickers(false);
                } else {
                    setIsSending(false);
                    console.error('Unable to GET notifications');
                }
            }).catch(error => {
                if (error.response.status === 403) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                } else {
                    window.location.href = '/login';
                    setIsSendingStickers(false);
                    console.error('Unable to Feedback Feedback List');
                    console.log(error);
                }
            });
            // }
            if (isMounted.current)
                setIsSendingStickers(false);
        };
        getFeedbackList();
        getFeedbackStickerList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t]);

    const exportFeedbacks = async() => {
        if(!is_online) return;
        let body = {};
        body['token'] = localStorage.getItem('token');
        await axios.post(API_EXPORT_FEEDBACK_URL, body).then((res) => {
            if (res.data.status) {
                setExportFinish(true);
            } else {
                window.location.href = '/error';
                console.error('ERROR while exporting data');
            }
        }).catch(error => {
            if (error.response.status === 403) {
                localStorage.removeItem('token');
                window.location.href = '/login';
            } else {
                // setExportFinish(true);
                window.location.href = '/error';
                console.error('ERROR while exporting data');
                console.log(error);
            }
        });
    }

    useEffect(() => {
        const sortStickers = () => {
            if (stickerList === null) return;
            let appreciations = [];
            let improvements = [];
            Object.keys(stickerList).map(function (item, index) {
                let obj_a = {};
                let obj_i = {};
                obj_a[item.toLowerCase()] = typeof stickerList[item.toLowerCase()].appreciation != 'undefined' ? stickerList[item.toLowerCase()].appreciation : 0;
                obj_i[item.toLowerCase()] = typeof stickerList[item.toLowerCase()].improvement != 'undefined' ? stickerList[item.toLowerCase()].improvement : 0;
                appreciations[index] = obj_a;
                improvements[index] = obj_i;
                return item.toLowerCase();
            });
            appreciations.sort(function (a, b) {
                return Object.values(b)[0] - Object.values(a)[0];
            });
            improvements.sort(function (a, b) {
                return Object.values(b)[0] - Object.values(a)[0];
            });
            setAprectiationStickerList(appreciations);
            setImprovementStickerList(improvements);
        }
        sortStickers();
    }, [stickerList]);

    return (
        <section className={feedbackList.length ? "my_feedback" : "empty_feedback"}>
            <Helmet>
                <title>
                    Orange {t("Openly")} | {t("breadcrumb.received_feedback")}
                </title>
            </Helmet>
            <div className="banner-essential_ask bg-color-digital_dark_grey_2">
                <div className="gridContainer body_m flex between padding_20_0" onClick={() => setShowHint(true)}>
                    <div>
                        <div className="txt-color-white txt-xxl-size bold m">{t("smart_tips.title_2_1")}<br/> {t("smart_tips.title_2_2")} <br/>{t("smart_tips.title_2_3")}</div>
                        <div className="txt-color-white txt-xxl-size bold dt">{t("smart_tips.title_2_1")} {t("smart_tips.title_2_2")} {t("smart_tips.title_2_3")}</div>
                        <span className="s_20"></span>
                        <button className="inverted s-size" onClick={() => setShowHint(true)}>{t("smart_tips.btn_cta")}</button>
                    </div>
                    <div className="flex center">
                        <img width="70" height="77" src="../assets/img/give-empty.svg" alt="Give Empty icon" />
                    </div>
                </div>
            </div>
            <section className={showHint ? "drawer-smart-tips-receive active" : "drawer-smart-tips-receive"}>
                <div className="drawer-smart-tips-receive_body" >
                    <div className="mt">
                        <div className="osk-header_feedback flex between padding_0_20 bg-color-black">
                            <div className="osk-header_feedback_logo flex center" onClick={() => {setShowHint(false)}}>
                                <i className="icon icon-arrow-previous txt-m-size txt-color-white"></i>
                                <span className="txt-xxl-size txt-color-white padding_0_0_0_20 bold">{t("smart_tips.back")}</span>
                            </div>
                        </div>
                    </div>

                    <section className="smart-tips-receive bg-color-digital_dark_grey_2">
                        <div className="smart-tips-receive_container padding_0_20">
                            <span className="s_40 mt"></span>
                            <div className="txt-color-white txt-caption-m-size bold">{t("smart_tips.title")}</div>
                            <span className="s_40"></span>
                            <div className="smart-tips-receive_container_item">
                                <div className="txt-color-white txt-l-size bold">
                                    {t("smart_tips.paragraph_1")}
                                </div>
                            </div>
                            <span className="s_30"></span>
                            <div className="smart-tips-receive_container_item">
                                <div className="txt-color-white txt-l-size">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_2"))}/>
                                </div>
                            </div>
                            <span className="s_30"></span>
                            <div className="smart-tips-receive_container_item">
                                <div className="txt-color-white txt-l-size">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_3"))}/>
                                </div>
                            </div>
                            <span className="s_30"></span>
                            <div className="smart-tips-receive_container_item">
                                <div className="txt-color-white txt-l-size">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_4"))}/>
                                </div>
                            </div>
                            <span className="s_30"></span>
                            <div className="smart-tips-receive_container_item">
                                <div className="txt-color-white txt-l-size txt-color-cta bold">
                                    <div dangerouslySetInnerHTML={createMarkup(t("smart_tips.paragraph_5"))}/>
                                </div>
                            </div>
                            <span className="s_50"></span>
                            <button className="inverted w_100 l-size" onClick={() => {setShowHint(false)}}>{t("smart_tips.go_cta")}</button>
                            <span className="s_40 mt"></span>
                        </div>
                    </section>
                </div>
            </section>

            {
                feedbackList.length ? (
                    <div className="gridContainer body_m breadcrumbs d">
                        <div className="gridcontainer_widht">

                            <span className="s_30"></span>
                            <span className="txt-m-size bold flex left-center">
                                {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid */}
                                <a href="#" onClick={() => history.push('/')}>{t('breadcrumb.homepage')}</a>  <i className="icon icon-arrow-next padding_0_10"></i> {t('breadcrumb.received_feedback')}
                            </span>
                            <span className="s_40"></span>
                        </div>
                    </div>
                ) : null
            }

            {
                feedbackList.length && !isSending ? (
                    <div>
                        <div className="gridContainer body_m my_feedback_header">
                            <div className="gridcontainer_widht">
                                <div className="my_feedback_header_item_button flex w_100 s_55 txt-l-size bold bg-color-white">
                                    <div className={tabActive === 'feedback' ? 'items button_feedback center w-100 active' : 'items button_feedback center w-100'} onClick={() => setTabActive('feedback')}>{t("received_feedback.tab_feedback")}</div>
                                    <div className={tabActive === 'stickers' ? 'items button_stickers center w-100 active' : 'items button_stickers center w-100'} onClick={() => setTabActive('stickers')}>{t("received_feedback.tab_sticker")}</div>
                                </div>
                            </div>
                        </div>
                        <div id="button_feedback" hidden={tabActive === 'stickers' ? 'hidden' : ''}>
                            <div className="gridContainer body_m my_feedback_container">
                                <div className="gridcontainer_widht">
                                    {
                                        unreadFeedbacks ? (
                                            <div>
                                                <span className="s_40"></span>
                                                <div className="my_feedback_container_title txt-xxl-size bold">{t("received_feedback.tab_unread")}</div>
                                                <span className="s_25"></span>
                                            </div>
                                        ): null
                                    }
                                    {
                                        feedbackList.map(function (item, index) {
                                            if (!item.read) {
                                                if(!unreadFeedbacks)
                                                    setUnreadFeedbacks(true);
                                                return (
                                                    <SingleFeedback key={item.guid +"_"+index + "_unread"} item={item} indexItem={index} itemList={feedbackList} />
                                                )
                                            }
                                            return null;
                                        })
                                    }
                                </div>
                            </div>
                            <div className="gridContainer body_m my_feedback_container">
                                <div className="gridcontainer_widht">
                                    {
                                        feedbackList.length ? (
                                            <div className="my_feedback_container">
                                                <span className="s_40"></span>
                                                <div className="my_feedback_container_title flex between">
                                                    <div className=" txt-xxl-size bold flex center">{t("received_feedback.tab_read")}</div>
                                                    {
                                                        is_online ? (
                                                            <button className="clean s-size" data-attr="modal-box-notification" onClick={() => { exportFeedbacks()}}> {t("received_feedback.tab_export")} <i className="icon icon-arrow-next txt-s-size padding_0_0_0_5"></i></button>
                                                        ) : null
                                                    }
                                                </div>
                                                <span className="s_25"></span>

                                                {
                                                    feedbackList.map(function (item, index) {
                                                        if (item.read) {
                                                            return (
                                                                <SingleFeedback key={item.guid + "_" + index + "_read"} item={item} indexItem={index} itemList={feedbackList} />
                                                            )
                                                        }
                                                        return null;
                                                    })
                                                }
                                                <span className="s_40"></span>
                                            </div>
                                        ): null
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="button_stickers" hidden={tabActive === 'feedback' ? 'hidden' : ''}>
                            <span className="s_30"></span>
                            <div className="gridContainer body_m bold">
                                <span className="s_20"></span>
                                {t("received_feedback.values_text")} <a href={t("received_feedback.values_url")} target="_blank" rel="noopener noreferrer" className="u">{t("received_feedback.values_text_here")}</a>
                            </div>
                            <span className="s_20"></span>
                            <div className="gridContainer body_m my_feedback_container">
                                <div className="gridcontainer_widht flex">
                                    <div className="my_feedback_container_item bg-color-white  appreciations w_50 flex col center-top margin_0_10_0_0">
                                        <span className="s_20"></span>
                                        <div className="my_feedback_container_item_title txt-xxl-size bold padding_5">{t("received_feedback.appreciations")}</div>
                                        <span className="s_30"></span>
                                        {
                                            apreciationStickerList.map(function(item, index){
                                                let stickername = Object.keys(item)[0];
                                                return  (
                                                    <Sticker key={stickername + "_" + index + "_green"} stickername={stickername} counter={item[stickername]} type={'green'} />
                                                )
                                            })
                                        }
                                    </div>

                                    <div className="my_feedback_container_item bg-color-digital_light_grey_4 improvement w_50 flex col center-top">
                                        <span className="s_20"></span>
                                        <div className="my_feedback_container_item_title txt-xxl-size bold padding_5">{t("received_feedback.improvement")}</div>
                                        <span className="s_30"></span>

                                        {
                                            improvementStickerList.map(function (item, index) {
                                                let stickername = Object.keys(item)[0];
                                                return (
                                                    <Sticker key={stickername + "_" + index + "_yellow"} stickername={stickername} counter={item[stickername]} type={'yellow'} />
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                            <span className="s_40"></span>
                        </div>
                    </div>
                ) : null
            }
            {
                !feedbackList.length && !isSending && !isSendingStickers ? (
                    <EmptyFeedbackList />
                ) : null
            }
            <div id="modal-box-succes" className="notification-succes">
                <div id="modal-box" className={exportFinish ? "migration active" : "migration"}>
                    <div className={exportFinish ? "popup-content short notification flex left-center padding_0_10 active" : "popup-content short notification flex left-center padding_0_10"}>
                        <div className="popup-close" data-popup-role="close" onClick={() => setExportFinish(false)}>×</div>
                        <div className="flex left-center padding_0_15_0_0">
                            <div><img src="../assets/img/succes-icon.svg" alt="" /></div>
                            <div className="txt-color-white txt-m-size bold padding_0_10_0_10">{t("received_feedback.exported")}</div>
                        </div>
                    </div>
                    <div className="popup-overlay"></div>
                </div>
            </div>
        </section>
    );
}

export default ReceivedFeedbackPage;
